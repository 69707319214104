<!-- Navbar -->
<nav *ngIf="isNavbarShown" class="navbar navbar-expand-lg" [style]="getStyle()">
  <div class="container-fluid">
    <button
      class="navbar__toggler"
      type="button"
      (click)="toggleSidebar()"
      aria-controls="sidebarMenu"
      aria-label="Toggle navigation"
    >
      <i class="ph ph-list sidebar-toggle"></i>
    </button>

    <a class="navbar-brand" href="#">
      <img class="navbar__logo" alt="Avalanche.report Logo" src="{{ environment.logoPath }}" />
    </a>

    <div class="navbar-collapse" id="navbarContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li *ngIf="authenticationService.isUserLoggedIn()" class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/bulletins']" ngxMousetrapKey="g b">
            {{ "sidebar.bulletins" | translate }}
          </a>
        </li>
        <li
          *ngIf="authenticationService.getActiveRegion()?.enableDangerSources && authenticationService.isUserLoggedIn()"
          class="nav-item"
        >
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/danger-sources']" ngxMousetrapKey="g d">
            {{ "sidebar.dangerSources" | translate }}
          </a>
        </li>
        <li
          *ngIf="authenticationService.getActiveRegion()?.enableObservations && authenticationService.isUserLoggedIn()"
          class="nav-item"
        >
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/observations']" ngxMousetrapKey="g o">
            {{ "sidebar.observations" | translate }}
          </a>
        </li>
        <li
          *ngIf="authenticationService.getActiveRegion()?.enableModelling && authenticationService.isUserLoggedIn()"
          class="nav-item"
        >
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/modelling/geosphere']" ngxMousetrapKey="g m">
            {{ "sidebar.geosphere" | translate }}
          </a>
        </li>
        <li *ngIf="authenticationService.getActiveRegion()?.enableModelling" class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/modelling/snowpack']" ngxMousetrapKey="g s">
            {{ "sidebar.snowpack" | translate }}
          </a>
        </li>
        <li *ngIf="authenticationService.getActiveRegion()?.enableModelling" class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/modelling/awsome']" ngxMousetrapKey="g a">
            {{ "sidebar.awsome" | translate }}
          </a>
        </li>
        <li
          *ngIf="authenticationService.getActiveRegion()?.enableWeatherbox && authenticationService.isUserLoggedIn()"
          class="nav-item"
        >
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/modelling/zamg-wbt']" ngxMousetrapKey="g w">
            {{ "sidebar.weatherbox" | translate }}
          </a>
        </li>
      </ul>
    </div>

    <ul class="navbar-nav d-flex flex-row gap-3 ms-auto">
      <li
        class="navbar-text"
        *ngIf="localStorageService.isTrainingEnabled"
        title="{{ 'training.timestamp' | translate }}"
      >
        <i class="ph ph-clock-afternoon"></i>
        {{ localStorageService.trainingTimestamp }}
      </li>
      <li class="nav-item dropdown" dropdown>
        <a
          class="nav-link dropdown-toggle navbar__lang-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          dropdownToggle
        >
          {{ translateService.currentLang }}
        </a>
        <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
          <li>
            <h6 class="dropdown-header">{{ "menu.language" | translate }}</h6>
          </li>
          <li *ngFor="let lang of translateService.langs">
            <a class="dropdown-item" (click)="localStorageService.setLanguage(lang)" role="button">
              {{ "menu." + lang | translate }}
              <i *ngIf="translateService.currentLang === lang" class="ph ph-check"></i>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown" dropdown>
        <a
          class="nav-link dropdown-toggle user-avatar"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          dropdownToggle
          ngxMousetrapKey="u"
        >
          <img
            *ngIf="authenticationService.getCurrentAuthor() && authenticationService.getCurrentAuthor().image"
            [src]="authenticationService.getUserImageSanitized(authenticationService.getCurrentAuthor().image)"
            class="user-avatar__img"
          />
          {{ authenticationService.getUsername() }}
        </a>
        <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
          <li>
            <h6 class="dropdown-header">{{ "menu.region" | translate }}</h6>
          </li>

          <li *ngFor="let region of authenticationService.getCurrentAuthorRegions(); let i = index">
            <a class="dropdown-item" (click)="changeRegion(region)" role="button" ngxMousetrapKey="{{ i + 1 }}">
              <i class="ph ph-globe"></i>
              {{ regionsService.getRegionName(region.id) }}
              <i *ngIf="authenticationService.getActiveRegionId() === region.id" class="ph ph-check"></i>
            </a>
          </li>

          <li>
            <hr class="dropdown-divider" />
          </li>

          <li>
            <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/education']" ngxMousetrapKey="e">
              <i class="ph ph-graduation-cap"></i>
              {{ "menu.education" | translate }}
            </a>
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>

          <li>
            <a
              *ngIf="authenticationService.isCurrentUserInRole(constantsService.roleForecaster)"
              class="dropdown-item"
              routerLinkActive="active"
              [routerLink]="['/statistics']"
            >
              <i class="ph ph-chart-line"></i>
              {{ "menu.statistics" | translate }}
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/settings']" ngxMousetrapKey="s">
              <i class="ph ph-gear"></i>
              {{ "menu.settings" | translate }}
            </a>
          </li>
          <li>
            <a
              *ngIf="authenticationService.isCurrentUserInRole(constantsService.roleAdmin)"
              class="dropdown-item"
              routerLinkActive="active"
              [routerLink]="['/admin']"
              ngxMousetrapKey="a"
            >
              <i class="ph ph-detective"></i>
              {{ "menu.admin" | translate }}
            </a>
          </li>

          <li>
            <hr class="dropdown-divider" />
          </li>

          <li>
            <a class="dropdown-item" href="#" routerLink="/pages/login" (click)="logout()" ngxMousetrapKey="l">
              <i class="ph ph-sign-out"></i>
              {{ "menu.logout" | translate }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>

<!-- Sidebar -->
<div class="sidebar" [class.sidebar--open]="isSidebarOpen" id="sidebarMenu">
  <div class="sidebar-content">
    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <li *ngIf="authenticationService.isUserLoggedIn()" class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/bulletins']">
          <i class="ph ph-snowflake"></i>
          {{ "sidebar.bulletins" | translate }}
        </a>
      </li>
      <li
        *ngIf="authenticationService.getActiveRegion()?.enableDangerSources && authenticationService.isUserLoggedIn()"
        class="nav-item"
      >
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/danger-sources']">
          <i class="ph ph-snowflake"></i>
          {{ "sidebar.dangerSources" | translate }}
        </a>
      </li>
      <li
        *ngIf="authenticationService.getActiveRegion()?.enableObservations && authenticationService.isUserLoggedIn()"
        class="nav-item"
      >
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/observations']">
          <i class="ph ph-eye"></i>
          {{ "sidebar.observations" | translate }}
        </a>
      </li>
      <li
        *ngIf="authenticationService.getActiveRegion()?.enableModelling && authenticationService.isUserLoggedIn()"
        class="nav-item"
      >
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/modelling/geosphere']">
          <i class="ph ph-sphere"></i>
          {{ "sidebar.geosphere" | translate }}
        </a>
      </li>
      <li *ngIf="authenticationService.getActiveRegion()?.enableModelling" class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/modelling/snowpack']" ngxMousetrapKey="g s">
          {{ "sidebar.snowpack" | translate }}
        </a>
      </li>
      <li *ngIf="authenticationService.getActiveRegion()?.enableModelling" class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/modelling/awsome']" ngxMousetrapKey="g a">
          {{ "sidebar.awsome" | translate }}
        </a>
      </li>
      <li
        *ngIf="authenticationService.getActiveRegion()?.enableWeatherbox && authenticationService.isUserLoggedIn()"
        class="nav-item"
      >
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/modelling/zamg-wbt']">
          {{ "sidebar.weatherbox" | translate }}
        </a>
      </li>
    </ul>
  </div>
</div>

<div class="app-body">
  <!-- Main content -->
  <main class="main">
    <router-outlet></router-outlet>
  </main>
</div>

<ng-template #changeRegionTemplate>
  <div class="modal-body text-center">
    <p>{{ "changeRegionDialog.message" | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="changeRegionModalConfirm()">
      {{ "changeRegionDialog.accept" | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="changeRegionModalDecline()">
      {{ "changeRegionDialog.reject" | translate }}
    </button>
  </div>
</ng-template>
