{
  "daytime": {
    "earlier": "inizio giornata",
    "later": "nel corso della giornata"
  },
  "login": {
    "title": "Login",
    "text": "Accedi al tuo account",
    "button": "Login",
    "stressLevel": "Livello di stress",
    "errorDialog": {
      "message": "Errore nome utente o password!",
      "accept": "Ok"
    },
    "placeholder": {
      "username": "Inserire nome utente",
      "password": "Inserire password"
    }
  },
  "sidebar": {
    "bulletins": "Valanghe.report",
    "dangerSources": "Origini di pericolo",
    "observations": "Osservazioni",
    "qfa": "QFA",
    "modelling": "Modelli",
    "modellingForecast": "Previsione",
    "modellingZamg": "Multi modelli dello ZAMG ",
    "modellingZamgECMWF": "ZAMG ECMWF-EPS",
    "modellingZamgCLAEF": "ZAMG CLAEF-EPS",
    "modellingZamgMeteogram": "GeoSphere meteogramma",
    "modellingSnowpack": "Modello SNOWPACK",
    "modellingSnowpackMeteo": "Dati meteo simulati SNOWPACK",
    "weatherbox": "Dashboard meteo",
    "awsome": "AWSOME",
    "snowpack": "SNOWPACK",
    "geosphere": "GeoSphere"
  },
  "menu": {
    "logout": "Logout",
    "language": "Lingue",
    "en": "Inglese",
    "de": "Tedesco",
    "fr": "Francese",
    "it": "Italiano",
    "es": "Spagnolo",
    "ca": "Catalano",
    "oc": "Aranese",
    "fullscreen": "A schermo pieno",
    "settings": "Impostazioni",
    "statistics": "Statistiche",
    "admin": "Admin",
    "region": "Meteo-nivo zona",
    "education": "Formazione"
  },
  "matrix": {
    "label": {
      "snowpackStability": "Stabilità del manto nevoso",
      "frequency": "Frequenza",
      "avalancheSize": "Dimensione valanga",
      "dangerRating": "Grado del pericolo",
      "override": "(modifica valutazione grado del pericolo)",
      "matrix": "Matrice",
      "referToFairStability": "Fare riferimento alla classe \"Discreta\"",
      "referToPoorStability": "Fare riferimento alla classe \"Scarsa\"",
      "dangerLevel1": "Grado di pericolo 1 (debole)"
    }
  },
  "dangerSources": {
    "status": {
      "missing": "manca",
      "available": "disponibile",
      "forecast": "previsione",
      "analysis": "analisi",
      "loading": "Caricamento origini di pericolo ...",
      "saveError": "L'origine di pericolo non poteva essere salvata!",
      "loadDangerSourcesError": "L'origine di pericolo non può essere caricato!",
      "loadVariantsError": "Le variazioni dell'origine di pericolo non può essere caricato!"
    },
    "variantStatus": {
      "active": "attivo",
      "dormant": "muto",
      "inactive": "inattivo"
    },
    "tooltip": {
      "read": "Leggi origine di pericolo",
      "edit": "Modifica l'origine di pericolo"
    },
    "table": {
      "loadMore": "Carica {{count}} più giorni",
      "title": {
        "forecast": "Previsione",
        "analysis": "Analisi"
      }
    },
    "create": {
      "variantStatus": "Status",
      "variantEditable": "Questa variazione può essere modificata.",
      "variantNotEditable": "Questa variazione non può essere modificata.",
      "noVariantsForSelectedDay": "Non sono state definite variazioni per questa data.",
      "tooltip": {
        "editDangerSource": "Modifica l'origine di pericolo",
        "loadVariants": "Carica le variazioni del giorno precedente",
        "edit": "Modifica le micro-zone",
        "copy": "Copia variazione",
        "delete": "elimina la variazione",
        "compare": "paragona le variazioni",
        "forecast": "paragona con la previsione"
      },
      "createDangerSource": "crea un'origine di pericolo",
      "createVariant": "crea una nuova variazione",
      "noDangerSources": "nessuna origine di pericolo",
      "title": {
        "avalanche": "valanga",
        "matrix": "Matrice",
        "characteristics": "Caratteristiche"
      },
      "label": {
        "glidingSnowActivity": "attività di slittamento",
        "snowHeight": "altezza neve",
        "zeroDegreeIsotherm": "Isoterma a 0°C",
        "looseSnowMoisture": "Umidità neve a debole coesione",
        "looseSnowGrainShape": "Tipologia grano neve a debole coesione",
        "slabGrainShape": "Tipologia grano lastrone",
        "slabThickness": "Spessore lastrone",
        "slabHandHardness": "Durezza mano lastrone",
        "slabHardnessProfile": "Durezza lastrone",
        "slabEnergyTransferPotential": "Potenzialità del lastrone di supportare la propagazione",
        "slabDistribution": "Distribuzione del lastrone",
        "weakLayerGrainShape": "Tipologia grani dello strato debole",
        "weakLayerGrainSize": "Dimensione grani dello strato debole",
        "weakLayerPersistent": "Persistenza dello strato debole",
        "weakLayerThickness": "Spessore dello strato debole",
        "weakLayerStrength": "Resistenza al taglio strato debole",
        "weakLayerWet": "Umidità dello strato debole",
        "weakLayerCrustAbove": "Crosta al di sopra dello strato debole",
        "weakLayerCrustBelow": "Crosta al di sotto dello strato debole",
        "weakLayerPosition": "Posizione dello strato debole nel manto nevoso",
        "weakLayerCreation": "Formazione di uno strato debole",
        "weakLayerDistribution": "Distribuzione dello strato debole",
        "dangerSpotRecognizability": "Riconoscibilità dei punti pericolosi",
        "remoteTriggering": "Distacco a distanza",
        "hasDaytimeDependency": "Dipendenza diurna",
        "dangerIncreaseWithElevation": "Pericolo aumenta con la quota",
        "runoutIntoGreen": "Valanghe arrivano fino ai prati",
        "highestDangerAspect": "Esposizione al pericolo più elevato",
        "dangerPeak": "Picco del pericolo",
        "slopeGradient": "Inclinazione del pendio",
        "naturalRelease": "Distacco spontaneo",
        "terrainTypes": "Tipo di terreno",
        "dangerSigns": "Segni d'allarme",
        "aspects": "Esposizione"
      },
      "editDangerSourceDialog": {
        "save": "Salva",
        "cancel": "Cancella"
      }
    }
  },
  "avalancheType": {
    "title": "Tipi di valanghe",
    "label": {
      "slab": "LASTRONE",
      "loose": "A DEBOLE COESIONE",
      "glide": "SLITTAMENTO"
    },
    "tooltip": {
      "slab": "Valanga di neve a lastroni",
      "loose": "Valanga di neve a debole coesione",
      "glide": "Valanga di slittamento"
    }
  },
  "elevation": {
    "title": "Quota",
    "high": "al di sotto",
    "low": "al di sopra",
    "treeline": "Limite del bosco"
  },
  "bulletins": {
    "title": "Valanghe.report",
    "tooltip": {
      "edit": "Modifica bollettino",
      "read": "Leggi bollettino",
      "copy": "Copia bollettino valanghe",
      "paste": "Incolla bollettino valanghe",
      "cancel": "Annulla la copia",
      "update": "Modifica",
      "submit": "Invia",
      "submitUpdate": "Invia update",
      "publishChange": "Pubblica ora (senza messaggi)",
      "publishUpdate": "Pubblica ora",
      "caaml": "Mostra CAAML",
      "json": "Mostra JSON",
      "create": "Crea bollettino valanghe",
      "preview": "Anteprima del bollettino valanghe (PDF)",
      "check": "Controlla bollettino valanghe",
      "info": "Mostra lo stato pubblicazione",
      "publishAll": "Pubblica tutte le meteo-nivo zone",
      "mediaFile": "Carica file media"
    },
    "status": {
      "submittedInfo": "Verrà pubblicato alle 17:00",
      "resubmittedInfo": "Verrà pubblicato alle 08:00",
      "publishedInfo" : "È stato pubblicato",
      "noPublicationInfo" : "NON verrà pubblicato automaticamente",
      "loadingPreview": "Creare un'anteprima in PDF ...",
      "loading": "Carica bollettino ...",
      "loadMoreBulletins": "Carica {{count}} più bollettini",
      "publishing": "Pubblica bollettino ...",
      "submitting": "Invia bollettino ...",
      "saveError": "Non é stato possibile salvare il bollettino!",
      "loadBulletinsError": "Non é stato possibile caricare il bollettino!"
    },
    "create": {
      "tooltip": {
        "edit": "Modifica le micro-zone",
        "copy": "Copia meteo-nivo zona",
        "delete": "Elimina regione",
        "undo": "Annulla l'ultima modifica apportata alla meteo-nivo zona",
        "redo": "Ripeti l'ultima modifica nella meteo-nivo zona",
        "createRegion": "Crea meteo-nivo zona",
        "cancel": "Cancella",
        "acceptSuggestion": "Accetta meteo-nivo zone suggerite",
        "rejectSuggestion": "Scarta meteo-nivo zone suggerite",
        "saveChanges": "Salva modifiche",
        "discardChanges": "Scarta modifiche",
        "incompleteAvalancheProblem": "Problema valanghivo incompleto",
        "saveBulletin": "Salva bollettino valanghe",
        "discardBulletin": "Scarta modifiche del bollettino valanghe",
        "loadBulletin": "Copia il bollettino valanghe del giorno precedente",
        "uploadJsonBulletin": "Carica il bollettino valanghe in formato JSON",
        "downloadJsonBulletin": "Scarica il bollettino valanghe in formato JSON",
        "daytimeDependency": "Dipendenza diurna",
        "treeline": "Limite del bosco",
        "tendency": {
          "decreasing": "Pericolo valanghe in diminuazione",
          "steady": "Pericolo valanghe stabile",
          "increasing": "Pericolo valanghe in aumento",
          "danger": "Pericolo valanghe"
        },
        "textcat": {
          "edit": "Modifica",
          "copy": "Copia",
          "paste": "Incolla",
          "example": "Carica testo campione",
          "delete": "Elimina"
        },
        "moveUpAvalancheProblem": "Sposta problema valanghivo verso l'alto",
        "moveDownAvalancheProblem": "Sposta problema valanghivo verso il basso",
        "dangerRatingDirectionUp": "Verso l'alto",
        "dangerRatingDirectionDown": "Verso il basso"
      },
      "save": " Salva",
      "discard": " Scarta",
      "back": " Indietro",
      "createAggregatedRegion": " Nuova meteo-nivo zona",
      "pasteAggregatedRegion": "Incolla meteo-nivo zona",
      "pendingSuggestions": "Proposte",
      "rejectSuggestion": "Rifiuta",
      "acceptSuggestion": "Accetta",
      "cancelCopyBulletin": "Cancella",
      "createAvalancheProblem": "Nuovo problema valanghivo",
      "ownRegions": "Proprie meteo-nivo zone",
      "foreignRegions": "Altre meteo-nivo zone",
      "externalRegions": "Meteo-nivo zone esterne",
      "mapSelectRegion": "Seleziona micro-zone sulla mappa",
      "statusHeader": "Stato del bollettino",
      "suggestions": "Proposte",
      "noBulletinsForSelectedDay": "Nessun micro-zona definita per questa data.",
      "autosave": "Salvato automaticamente",
      "regionEditable": "Questa meteo-nivo zona può essere modificata.",
      "regionNotEditable": "Questa meteo-nivo zona non può essere modificata.",
      "regionLocked": "Questa meteo-nivo zona è bloccata.",
      "changeDangerRatingElevation": "Estendi il pericolo valanghe",
      "mapTop": "Mappa in alto",
      "mapLeft": "Mappa a lato",
      "showNotes": "Mostra gli appunti",
      "hideNotes": "Nascondi gli appunti",
      "incompleteAvalancheProblem": "Incompleto",
      "label": {
        "importantObservations": "Osservazioni importanti",
        "avalancheProblems": "Problema valanghivo",
        "aspects": "Esposizione",
        "highlights": "Allerta speciale",
        "avActivityHighlights": "Titolo situazione pericolo valanghe",
        "avActivityComment": "Descrizione pericolo valanghe",
        "snowpackStructureComment": "Descrizione struttura manto nevoso",
        "dangerPatterns": "Situazioni tipo",
        "tendencyComment": "Descrizione della tendenza",
        "notes": "Commenti"
      },
      "copyRegion": {
        "label": "Copia la meteo-nivo zona in un altro bollettino",
        "select": "Incolla in ...",
        "currentBulletin": "Questo bollettino"
      },
      "title": {
        "avalancheProblem": "Problemi valanghivi",
        "dangerDescription": "Descrizione pericolo valanghe",
        "forenoon": "Mattina",
        "afternoon": "Pomeriggio",
        "snowpackStructure": "Struttura manto nevoso",
        "tendency": "Tendenza",
        "showTranslations": " Mostra traduzione",
        "hideTranslations": " Nascondi traduzione"
      },
      "placeholder": {
        "notes": "Scrivi tuo commenti qui..."
      },
      "pmDialog": {
        "noIFrameSupport": "Il tuo browser non supporta IFrames!"
      },
      "discardDialog": {
        "message": "Vuoi scartare le modifiche?",
        "accept": "Sí",
        "reject": "No"
      },
      "deleteAggregatedRegionDialog": {
        "message": "Vuoi cancellare la meteo-nivo zona?",
        "accept": "Sí",
        "reject": "No"
      },
      "noRegionDialog": {
        "message": "Almeno una micro-area deve appartenere la tua Provincia.",
        "accept": "Ok"
      },
      "loadDialog": {
        "message": "Vuoi caricare il bollettino valanghe del giorno precedente?",
        "accept": "Sí",
        "reject": "No"
      },
      "loadAutoSaveDialog": {
        "message": "Vuoi caricare il bollettino valanghe salvato automaticamente?",
        "accept": "Sí",
        "reject": "No"
      },
      "saveErrorDialog": {
        "message": "Errore salvando il bollettino valanghe!",
        "accept": "Ok"
      },
      "changeErrorDialog": {
        "message": "Errore durante la modificazione del bollettino valanghe!",
        "accept": "Ok"
      },
      "loadingErrorDialog": {
        "message": "Errore durante il caricamento del bollettino valanghe!",
        "accept": "Ok"
      },
      "loadingJsonFileErrorDialog": {
        "message": "C'è stato un errore nel caricamento dei bollettini dal file JSON!",
        "accept": "Ok"
      },
      "avalancheProblemErrorDialog": {
        "message": "Manca il problema tipico valanghivo, l'esposizione, la stabilità del manto nevoso, la frequenza e/o la dimensione valanghe per almeno un problema valanghivo",
        "accept": "Ok"
      },
      "loadAvActivityCommentExampleTextDialog": {
        "message": "Per quale situazione vuoi caricare il testo?",
        "newSnow": "Neve fresca",
        "windSlab": "Lastroni da vento",
        "persistentWeakLayers": "Strati deboli persistenti",
        "wetSnow": "Neve bagnata",
        "glidingSnow": "Valanghe di slittamento",
        "favourableSituation": "Situazione favorevole",
        "cancel": "Cancella"
      },
      "removeDaytimeDependencyDialog" : {
        "message": "Quali problemi valanghivi vuoi mantenere?",
        "cancel": "Cancella"
      },
      "strategicMindsetDialog" : {
        "title": "Strategic Mindsets",
        "introduction": "Gli “Strategic Mindsets” sono intesi come espressione del nostro atteggiamento mentale nei confronti della situazione valanghiva e del suo sviluppo nella regione corrispondente. Non dovrebbero derivare direttamente dalla valutazione del pericolo, ma piuttosto essere compresi e utilizzati come un'impressione intuitiva della situazione generale. I “Strategic Mindsets” sono quindi principalmente uno strumento di comunicazione. Utilizzando i mindset e le definizioni associate, noi previsori possiamo scambiare in modo rapido ed efficiente informazioni sullo sviluppo della situazione di pericolo e trovare un linguaggio comune per la comunicazione.",
        "description": {
          "title": "Descrizione:",
          "text": {
            "assessment": "C'è una <b>grande incertezza</b> nella valutazione del pericolo a causa delle<b>informazioni insufficienti</b> osservati sul terreno.",
            "stepping_out": "<b>Tendenza alla diminuzione</b> della situazione di pericolo (anche all'interno di un grado di pericolo).",
            "status_quo": "Stessa situazione di pericolo.<b>Nessun cambiamento significativo</b> rispetto al giorno precedente.",
            "stepping_back": "<b>Tendenza all'aumento</b> della situazione di pericolo (anche all'interno di un livello di pericolo).",
            "entrenchment": "<b>Situazione di pericolo quasi costante e tesa.</b> Il pericolo principale deriva da (a) <b>uno strato debole persistente</b>(s) suscettibile al distacco.",
            "free_ride": "<b>Debole pericolo di valanghe</b> con un manto nevoso generalmente stabile. Sono possibili solo valanghe di dimensioni piccole nei terreni estremamente ripidi. ",
            "high_alert": "Situazione eccezionale con <b>pericolo di valanghe forte o molto forte</b>. Sono possibili valanghe spontanee di <b>dimensioni molto grandi o estreme </b>",
            "spring_diurnal": "Determinanti per la valutazione del rischio sono valanghe di neve umida nel corso della giornata che sono soggetti ad un <b>cicolo diurno chiaro</b>."
          }
        },
        "communication": {
          "title": "Comunicazione",
          "text": {
            "assessment": "La situazione valanghiva deve essere verificata a livello locale. Un comportamento prudente è opportuno.",
            "stepping_out": "Graduale diminuzione del pericolo. Tuttavia, occorre ancora prudenza e valutare localmente il miglioramento della situazione prima di addentrarsi in terreni più rischiosi. ",
            "status_quo": "La situazione di pericolo è invariata. Il riscontro degli osservatori aiuta a delineare meglio la situazione di pericolo. I terreni più favorevoli devono essere comunicati e messi in evidenza.",
            "stepping_back": "Comunicazione chiara dell'aumento del pericolo di valanghe, anche all'interno di un grado di pericolo. Informazioni sulle cause supportano la comprensibilità della comunicazione.",
            "entrenchment": "Sono necessarie cautela e moderazione. È difficile che la situazione cambi. Se possibile, fornire informazioni il più possibile precise sulla distribuzione e sulla suscettibilità al distacco. Maggiore comunicazione delle osservazioni che rendono evidente la situazione e inducono alla un comportamento prudente.",
            "free_ride": "Poco testo. Sottolineare la situazione generale favorevole e le zone di pericolo potenzialmente isolate.",
            "high_alert": "Comunicazione su tutti i canali. L'attenzione non è più rivolta agli sport invernali. Pericolo anche in aree solitamente non a rischio di valanghe.",
            "spring_diurnal": "Situazione favorevole al mattino, pericolo in aumento durante il giorno. La valutazione va fatta localmente osservando l'ammorbidimento della crosta da fusione e rigelo e la crescente umidificazione del manto nevoso."
          }
        },
        "examples": {
          "title": "Esempi:",
          "text": {
            "assessment": "Inizio stagione.",
            "stepping_out": "Un raffreddamento porta a una diminuzione del pericolo di valanghe di neve bagnata dopo una fase di tempo caldo e umido.",
            "status_quo": "Problema persistente di lastroni da vento con temperature fredde, neve a debole coesione e vento.",
            "stepping_back": "Abundant fresh snowfall leads to an increase in the danger situation.",
            "entrenchment": "Critical persistent weak layers problem with potential for dangerously large avalanches. The situation is only stabilising very slowly and requires a great deal of restraint.",
            "free_ride": "Low avalanche danger, no persistent weak layers, all terrain has already been groomed.",
            "high_alert": "Danger level 4 or higher due to large amounts of fresh snow and/or problematic, persistent weak layers. ",
            "spring_diurnal": "Spring situation with dry high-pressure weather and moderate temperatures with otherwise mostly stable old snow cover."
          }
        },
        "strategicMindset": {
          "assessment": "High Uncertainty",
          "stepping_out": "Improving",
          "status_quo": "Status Quo",
          "stepping_back": "Deteriorating",
          "entrenchment": "Persistent Unstable",
          "free_ride": "Favorable",
          "high_alert": "High Alert",
          "spring_diurnal": "Spring Diurnal"
        }
      },
      "decisionTree" : {
        "filepath": "./assets/img/decision_tree-it.svg",
        "feedbackURL": "https://admin.avalanche.report/umfrage-entscheidungsbaum/en/",
        "decisionTree" : "Albero decisionale",
        "feedback" : "Feedback",
        "save": "Salva",
        "discard": "Scarta"
      }
    },
    "table": {
      "title": {
        "date": "Data",
        "status": {
          "AT-02": "Carinzia",
          "AT-03": "Bassa Austria",
          "AT-04": "Alta Austria",
          "AT-05": "Salisburghese",
          "AT-06": "Stiria",
          "AT-07": "Tirolo",
          "AT-08": "Vorarlberg",
          "CH" : "Svizzera",
          "DE-BY" : "Baviera",
          "GEOSPHERE": "GEOSPHERE",
          "IT-32-TN": "Trentino",
          "IT-32-BZ": "Alto Adige",
          "ES-CT-L": "Aran"
        }
      },
      "status": {
        "missing": "manca",
        "draft": "bozza",
        "submitted": "inviato",
        "published": "pubblicato",
        "updated": "aggiornato",
        "resubmitted": "versione aggiornata inviata",
        "republished": "versione aggiornata pubblicata"
      },
      "publishBulletinsDialog": {
        "message": "Vuoi pubblicare il bollettino valanghe?",
        "missingDangerRating": "Grado di pericolo non definito",
        "missingRegion": "Regioni non valutate",
        "duplicateRegion": "Meteo-nivo zone valutate più volte",
        "missingAvActivityHighlights": "Manca il titolo situazione di pericolo",
        "missingAvActivityComment": "Manca la valutazione pericolo valanghe",
        "missingSnowpackStructureHighlights": "Manca il titolo della descrizione manto nevoso",
        "missingSnowpackStructureComment": "Manca la descrizione del manto nevoso",
        "pendingSuggestions": "Proposta non confermata",
        "incompleteTranslation": "Traduzione incompleta",
        "accept": "Sí",
        "reject": "No"
      },
      "publicationStatusDialog": {
        "headline": "Stato della pubblicazione",
        "publishedBy": "Pubblicato da: ",
        "publishedAt": "Pubblicato alle: ",
        "validFor": "Valido per: ",
        "title": {
          "prod": "Cose serie",
          "test": "Test system",
          "task": "Attività",
          "language": {
            "all": "TUTTO",
            "de": "DE",
            "it": "IT",
            "en": "EN"
          }
        },

        "email": {
          "label": "Email",
          "tooltip": "Inviare Email",
          "success": "{{prefix}}Email inviati con successo!",
          "error": "{{prefix}}Non è stato possibile inviare le Email!"
        },
        "telegram": {
          "label": "Messagio Telegram",
          "tooltip": "Invia messaggio via Telegram",
          "success": "{{prefix}}Messaggi via Telegram inviati con successo!",
          "error": "{{prefix}}Messaggi di Telegram non possono essere inviati!"
        },
        "push": {
          "label": "Notifica push",
          "tooltip": "Invia notifica push",
          "success": "{{prefix}}Notifiche push inviate con successo!",
          "error": "{{prefix}}Non è stato possibile inviare le notifiche push!"
        },
        "accept": "Ok"
      },
      "mediaFileDialog": {
        "headline": "File media",
        "important": "IMPORTANTE",
        "missingFile": "Nessun file selezionato!",
        "uploadError": "Upload fallito!",
        "accept": "Inviare",
        "reject": "Cancellare"
      },
      "publishBulletinsErrorDialog": {
        "message": "Errore pubblicando il bollettino valanghe!",
        "accept": "Ok"
      },
      "submitBulletinsDialog": {
        "message": "Vuoi inviare il bollettino valanghe?",
        "missingDangerRating": "Grado di pericolo non definito",
        "missingRegion": "Micro-zone non valutate",
        "missingAvActivityHighlights": "Manca titolo situazione di pericolo",
        "missingAvActivityComment": "Manca valutazione pericolo valanghe",
        "missingSnowpackStructureHighlights": "Manca il titolo della descrizione manto nevoso",
        "missingSnowpackStructureComment": "Manca la descrizione del manto nevoso",
        "pendingSuggestions": "Proposta non confermata",
        "accept": "Sí",
        "reject": "No"
      },
      "submitBulletinsErrorDialog": {
        "message": "Errore durante l'invio del bollettino valanghe!",
        "accept": "Ok"
      },
      "submitBulletinsDuplicateRegionDialog": {
        "message": "Micro-aree valutate più volte! Non è possibile inviare il bollettino valanghe.",
        "accept": "Ok"
      },
      "checkBulletinsErrorDialog": {
        "message": "Errore verficando il bollettino valanghe!",
        "accept": "Ok"
      },
      "previewErrorDialog": {
        "message": "L'anteprima non può essere caricata!",
        "accept": "Ok"
      },
      "checkBulletinsDialog": {
        "message": "Controllare bollettino valanghe",
        "missingDangerRating": "Grado di pericolo non definito",
        "missingRegion": "Micro-aree non valutate",
        "missingAvActivityHighlights": "Manca titolo situazione di pericolo",
        "missingAvActivityComment": "Manca valutazione pericolo valanghe",
        "missingSnowpackStructureHighlights": "Manca il titolo della descrizione manto nevoso",
        "missingSnowpackStructureComment": "Manca la descrizione del manto nevoso",
        "pendingSuggestions": "Proposta non confermata",
        "incompleteTranslation": "Traduzione incompleta",
        "ok": "(tutto va bene)",
        "accept": "Ok"
      },
      "publishAllDialog": {
        "message": "Vuoi pubblicare tutte le regioni?",
        "accept": "Sí",
        "reject": "No"
      }
    }
  },
  "training": {
    "training": "Training",
    "timestamp": "Momento del training"
  },
  "admin": {
    "loadingStatistics": "Creando CSV ...",
    "server-configuration": {
      "title": "dServer",
      "button": {
        "create": "Crea server",
        "save": "Salva"
      },
      "table": {
        "name": "Nome",
        "username": "Nome utente",
        "password": "Password",
        "publishAt5PM": "Publica alle 17:00",
        "publishAt8AM": "Publica alle 08:00",
        "pdfDirectory": "Cartella per PDF",
        "htmlDirectory": "Cartella per HTML",
        "serverImagesUrl": "Path alle immagini sul server",
        "mapsPath": "Path alle mappe",
        "mediaPath": "Path ai file media",
        "mapProductionUrl": "URL per la produzione di mappe",
        "apiUrl": "API URL"
      },
      "success": "Configurazione salvata con successo",
      "error": "Non é stato possibile salvare le configurazione"
    },
    "region-configuration": {
      "title": "Regione",
      "button": {
        "create": "Crea regione",
        "save": "Salva"
      },
      "table": {
        "general": {
          "title": "In generale",
          "id": "ID",
          "microRegions": "Numero micro-zone",
          "subRegions": "Sottoregioni",
          "superRegions": "Sovraregioni",
          "neighborRegions": "Regioni confinanti"
        },
        "publication": {
          "title": "Pubblicazione",
          "publishBulletins": "Publica bollettino valanghe",
          "publishBlogs": "Publica i post blog",
          "createCaamlV5": "Crea CAAML v5",
          "createCaamlV6": "Crea CAAML v6",
          "createJson": "Crea JSON",
          "createMaps": "Crea mappe",
          "createPdf": "Crea PDF",
          "sendEmails": "Invia email",
          "createSimpleHtml": "Crea HTML semplici",
          "sendTelegramMessages": "Invia messaggi via Telegram",
          "sendPushNotifications": "Invia notifiche push",
          "pdfColor": "PDF in colore",
          "emailColor": "Email in colore",
          "pdfMapYAmPm": "Y per mappa PDF (am/pm)",
          "pdfMapYFd": "Y per mappa PDF (fd)",
          "pdfMapWidthAmPm": "Larghezza della mappa per PDF (am/pm)",
          "pdfMapWidthFd": "Larghezza della mappa per PDF (fd)",
          "pdfMapHeight": "Altezza mappa per PDF",
          "pdfFooterLogo": "Logo per piè di pagina PDF",
          "pdfFooterLogoColorPath": "Logo per piè di pagina PDF (colore)",
          "pdfFooterLogoBwPath": "Logo per piè di pagina PDF (bianco/nero)",
          "mapXmax": "Mappa X massimo",
          "mapXmin": "Mappa X minimo",
          "mapYmax": "Mappa Y massimo",
          "mapYmin": "Mappa Y minimo",
          "simpleHtmlTemplateName": "Template HTML semplice",
          "geoDataDirectory": "Cartella geodati",
          "mapLogoColorPath": "Logo per mappa (colore)",
          "mapLogoBwPath": "Logo per mappa (bianco/nero)",
          "mapLogoPosition": "Posizione del logo nella mappa",
          "mapCenterLat": "Posizione centrale della mappa (lat)",
          "mapCenterLng": "Posizione centrale della mappa (long)",
          "imageColorbarColorPath": "Barra colori (colore)",
          "imageColorbarBwPath": "Barra colori (bianco/nero)"
        },
        "components": {
          "title": "Componenti",
          "enableDangerSources": "Enable danger sources",
          "enableObservations": "Enable observations",
          "enableModelling": "Enable modelling",
          "enableWeatherbox": "Enable weather box"
        },
        "configuration": {
          "title": "Configuration",
          "showMatrix": "Mostra matrice",
          "enableMediaFile": "Attiva file media",
          "enableStrategicMindset": "Enable strategic mindset",
          "enableStressLevel": "Enable stress level",
          "enableAvalancheProblemCornices": "Attivare problema tipico valanghivo CORNICI",
          "enableAvalancheProblemNoDistinctAvalancheProblem": "Attivare problema tipico valanghivo NESSUN PROBLEMA VALANGHIVO EVIDENTE"
        }
      },
      "success": "Configurazione salvata con successo",
      "error": "Non é stato possibile salvare la configurazione"
    },
    "users": {
      "title": "Utenti",
      "button": {
        "create": "Crea utente"
      },
      "tooltip": {
        "edit": "Modifica utente",
        "changePassword": "Cambia password",
        "delete": "Cancella utente"
      },
      "table": {
        "name": "Nome",
        "email": "Email",
        "organization": "Organizzazione",
        "regions": "Regioni",
        "roles": "Ruoli"
      },
      "deleteUserModalDialog": {
        "message": "Voi cancellare questo utente?",
        "accept": "Sì",
        "reject": "No"
      },
      "deleteUser": {
        "success": "Utente cancellato",
        "error": "L'utente non può essere cancellato"
      },
      "createUser": {
        "success": "Utente creato",
        "error": "L'utente non può essere creato"
      },
      "updateUser": {
        "success": "Utente aggiornato",
        "error": "L'utente non può essere aggiornato"
      }
    },
    "createUser": {
      "table": {
        "image": "Immagine",
        "name": "Nome",
        "email": "Email",
        "organization": "Organizazzione",
        "password": "Password",
        "password2": "Ripeti la password",
        "roles": "Ruoli",
        "regions": "Regioni"
      },
      "error": {
        "name": {
          "required": "Inserire un nome"
        },
        "email": {
          "required": "Inserire un indirizzo Email",
          "invalid": "L'indirizzo Email non è valido"
        },
        "password": {
          "required": "Inserisci una password",
          "length": "La password deve essere di almeno 8 caratteri",
          "mismatch": "Le password non corrispondono"
        }
      },
      "button": {
        "create": "Crea utente",
        "update": "Aggiornare utente",
        "cancel": "Cancellare"
      }
    },
    "observations": {
      "table": "Tabella",
      "map": "Mappa",
      "gallery": "Galleria",
      "filters": "Filtri",
      "title": "Osservazioni",
      "newObservation": "Nuova osservazione",
      "exportObservations": "Esporta osservazioni",
      "toggleFilterBar": "Show/hide filter bar",
      "button": {
        "submit": "Scarica"
      },
      "label": {
        "dateRange": "Periodo",
        "observer": "Osservatore"
      },
      "selectRegion": {
        "placeholder": "Regione",
        "selectionLabel": "{0} meteo-nivo zona/e selezionata/e"
      },
      "selectSources": {
        "placeholder": "origine dati",
        "selectionLabel": "{0} source(s) selected"
      },
      "charts": {
        "charts": "Charts",
        "nan": "Without specification",
        "classify": "Classify",
        "label": "Label",
        "invert": "Invert",
        "reset": "Reset",
        "aspect": {
          "caption": "Esposizione"
        },
        "elevation": {
          "caption": "Quota"
        },
        "stability": {
          "caption": "Stability"
        },
        "observationType": {
          "caption": "Observation Type"
        },
        "importantObservation": {
          "caption": "Important"
        },
        "avalancheProblem": {
          "caption": "Problema tipico valanghivo"
        },
        "dangerPattern": {
          "caption": "Danger Pattern"
        },
        "days": {
          "caption": "Giorno"
        }
      }
    },
    "blog": {
      "title": "Blog",
      "language": {
        "title": "Lingua",
        "de": "DE",
        "it": "IT",
        "en": "EN"
      },
      "all": {
        "tooltip": "Invia Email, Telegram e Push",
        "success": "{{prefix}}Email, Telegram e Push inviati con successo!",
        "error": "{{prefix}}Non è stato possibile inviare Email, Telegram e Push!"
      },
      "email": {
        "tooltip": "Inviare Email",
        "success": "{{prefix}}Email inviato con successo!",
        "error": "{{prefix}}Non è stato possibile inviare Email!"
      },
      "telegram": {
        "tooltip": "Pubblicazione via Telegram channel",
        "success": "{{prefix}}Telegram inviato con successo",
        "error": "{{prefix}}Non è stato possibile inviare i messaggi Telegram!"
      },
      "push": {
        "tooltip": "Invia tramite notifiche push",
        "success": "{{prefix}}Push inviato con successo",
        "error": "{{prefix}}Non è stato possibile inviare notifiche Push"
      }
    }
  },
  "settings": {
    "title": "Impostazioni",
    "changePassword": {
      "title": "Cambia password",
      "oldPassword": {
        "label": "Password attuale",
        "placeholder": "Password attuale"
      },
      "newPassword1": {
        "label": "Nuova password",
        "placeholder": "Nuova password"
      },
      "newPassword2": {
        "label": "Nuova password (ripetere)",
        "placeholder": "Nuova password"
      },
      "submit": "Cambia password",
      "passwordChanged": "Password modificata con sucesso",
      "passwordChangeError": "Non é stato possibile cambiare la password",
      "passwordIncorrect": "Password attuale non corretta",
      "error": {
        "oldPassword": {
          "required": "Inserire password attuale"
        },
        "newPassword": {
          "required": "Inserire nuova password",
          "mismatch": "Le password non corrispondono"
        }
      }
    },
    "display": {
      "title": "Mostra",
      "table": {
        "showCaaml": "Mostra CAAML",
        "showJson": "Mostra JSON"
      }
    }
  },
  "statistics": {
    "title": "Statistiche",
    "button": {
      "submit": "Scarica"
    },
    "label": {
      "dateRange": "Periodo",
      "extended": "Esteso",
      "duplicates": "Doppiete"
    }
  },

  "dangerRating": {
    "noRating": "senza valutazione",
    "low": "debole",
    "moderate": "moderato",
    "considerable": "marcato",
    "high": "forte",
    "veryHigh": "molto forte"
  },
  "avalancheProblem": {
    "newSnow": "Neve fresca",
    "new_snow": "Neve fresca",
    "windSlab": "Neve ventata",
    "wind_slab": "Neve ventata",
    "persistentWeakLayers": "Strati deboli persistenti",
    "persistent_weak_layers": "Strati deboli persistenti",
    "wetSnow": "Neve bagnata",
    "wet_snow": "Neve bagnata",
    "glidingSnow": "Valanghe di slittamento",
    "gliding_snow": "Valanghe di slittamento",
    "cornices": "Cornice",
    "noDistinctAvalancheProblem": "No distinct avalanche problem",
    "no_distinct_avalanche_problem": "No distinct avalanche problem",
    "favourableSituation": "Situazione favorevole",
    "favourable_situation": "Situazione favorevole"
  },
  "dangerPattern": {
    "dp1": "st.1: strato debole persistente basale",
    "dp2": "st.2: valanga di slittamento",
    "dp3": "st.3: pioggia",
    "dp4": "st.4: freddo su caldo / caldo su freddo",
    "dp5": "st.5: neve dopo un lungo periodo di freddo",
    "dp6": "st.6: neve fresca fredda a debole coesione e vento",
    "dp7": "st.7: zone con poca neve durante inverni ricchi di neve",
    "dp8": "st.8: brina di superficie sepolta",
    "dp9": "st.9: neve pallottolare coperta da neve fresca",
    "dp10": "st.10: situazione primaverile"
  },
  "strategicMindset": {
    "title": "Strategic Mindset",
    "assessment": "High Uncertainty",
    "stepping_out": "Improving",
    "status_quo": "Status Quo",
    "stepping_back": "Deteriorating",
    "entrenchment": "Persistent Unstable",
    "free_ride": "Favorable",
    "high_alert": "High Alert",
    "spring_diurnal": "Spring Diurnal"
  },
  "observationType": {
    "TimeSeries": "Time series",
    "Incident": "Incident",
    "Profile": "Snow profile",
    "Closure": "Closure",
    "Blasting": "Blasting",
    "Avalanche": "valanga",
    "Evaluation": "Evaluation",
    "SimpleObservation": "Simple observation",
    "Webcam": "Webcam"
  },
  "importantObservation": {
    "SnowLine": "Snow line",
    "SurfaceHoar": "Surface hoar",
    "Graupel": "Graupel",
    "StabilityTest": "Stability test",
    "IceFormation": "Ice formation",
    "VeryLightNewSnow": "Very light new snow"
  },
  "aspect": {
    "N": "N",
    "NE": "NE",
    "E": "E",
    "SE": "SE",
    "S": "S",
    "SW": "SO",
    "W": "O",
    "NW": "NO"
  },
  "role": {
    "SUPERADMIN": "Super Administrator",
    "ADMIN": "Amministratore",
    "FORECASTER": "Previsore",
    "FOREMAN": "Caposquadra",
    "OBSERVER": "Osservatore"
  },
  "tendency": {
    "decreasing": " Pericolo valanghe in diminuazione",
    "steady": " Pericolo valanghe stabile",
    "increasing": " Pericolo valanghe in aumento"
  },
  "snowpackStability": {
    "unknown": "unknown",
    "good": "buona",
    "fair": "discreta",
    "poor": "scarsa",
    "very_poor": "molto scarsa"
  },
  "frequency": {
    "none": "pochissimi",
    "few": "pochi",
    "some": "alcuni",
    "many": "molti"
  },
  "avalancheSize": {
    "small": "piccola",
    "medium": "media",
    "large": "grande",
    "very_large": "molto grande",
    "extreme": "estremamente grande"
  },
  "complexity": {
    "easy": "Facile",
    "challenging": "Impegnativo",
    "complex": "Complesso"
  },
  "glidingSnowActivity": {
    "low": "debole",
    "medium": "media",
    "high": "forte"
  },
  "wetness": {
    "dry": "dry",
    "moist": "moist",
    "wet": "wet"
  },
  "characteristic": {
    "low": "debole",
    "medium": "media",
    "high": "forte",
    "very_high": "molto forte"
  },
  "thickness": {
    "thin": "thin",
    "thick": "thick"
  },
  "snowpackPosition": {
    "upper": "upper",
    "middle": "middle",
    "lower": "lower",
    "ground": "ground"
  },
  "creationProcess": {
    "radiation_recrystallization": "radiation",
    "diurnal_recrystallization": "diurnal",
    "melt_layer_recrystallization": "melt layer"
  },
  "recognizability": {
    "very_easy": "very easy",
    "easy": "easy",
    "hard": "hard",
    "very_hard": "very hard"
  },
  "distribution": {
    "isolated": "isolato",
    "specific": "determinato",
    "widespread": "diffuso"
  },
  "probability": {
    "likely": "Crosta sopra lo strato debole?",
    "possible": "possible",
    "unlikely": "unlikely"
  },
  "detailedDaytime": {
    "evening": "evening",
    "first_night_half": "first night half",
    "second_night_half": "second night half",
    "morning": "morning",
    "forenoon": "forenoon",
    "afternoon": "afternoon"
  },
  "slopeGradient": {
    "moderately_steep": "moderately steep",
    "steep": "steep",
    "very_steep": "very steep",
    "extremely_steep": "extremely steep"
  },
  "handHardness": {
    "fist": "F",
    "four_fingers": "4F",
    "one_finger": "1F",
    "pencil": "P",
    "knife": "K",
    "ice": "I"
  },
  "hardnessTendency": {
    "decreasing": "decreasing with height",
    "steady": "uniform",
    "increasing": "increasing with height"
  },
  "terrainType": {
    "gullies": "gullies",
    "bowls": "bowls",
    "pass_areas": "pass areas",
    "ridge_lines": "ridge lines"
  },
  "dangerSign": {
    "shooting_cracks": "shooting cracks",
    "whumpfing": "whumpfing"
  },
  "changeRegionDialog": {
    "message": "Do you want to change the region?",
    "accept": "Sì",
    "reject": "No"
  },
  "zamg": {
    "table": "Tabella",
    "map": "Mappa",
    "region": "Regione",
    "newSnow": "Neve fresca"
  },
  "snowpack": {
    "plotType": {
      "LWC_stratigraphy": "Neve bagnata: contenuto in acqua liquido",
      "wet_snow_instability": "Neve bagnata: Instabilità neve bagnata",
      "Sk38_stratigraphy": "strati deboli persistenti: Indice stabilità SK38",
      "stratigraphy": "strati deboli persistenti: stratigrafia"
    },
    "aspect": {
      "flat": "piano",
      "north": "Versante nord",
      "south": "Versante sud"
    },
    "meteo": {
      "new_snow_plot_3day": "Neve fresca (3g)",
      "new_snow_plot_7day": "Neve fresca (7g)",
      "new_snow_plot_1month": "Neve fresca (31g)",
      "new_snow_plot_season": "Neve fresca (stagione)",
      "new_snow_plot_forecast": "Neve fresca (previsione)",
      "wet_snow_plot_3day": "Neve bagnata (3g)",
      "wet_snow_plot_7day": "Neve bagnata (7g)",
      "wet_snow_plot_1month": "Neve bagnata (31g)",
      "wet_snow_plot_season": "Neve bagnata (stagione)",
      "wet_snow_plot_forecast": "Neve bagnata (previsione)",
      "HS_table_24h": "Tabella altezza neve (1g)",
      "HS_table_72h": "Tabella altezza neve (3g)",
      "HS_table_season": "Tabella altezza neve (stagione)",
      "HS_table_forecast": "Tabella altezza neve (previsione)",
      "TA_table_24h": "Tabella temperatura (1g)",
      "TA_table_72h": "Tabella temperatura (3ore)",
      "TA_table_season": "Tabella temperatura (stagione)"
    }
  },
  "grainShape": {
    "PP": {
      "code": "PP",
      "class": "Precipitation Particles"
    },
    "PPgp": {
      "code": "PPgp",
      "class": "Graupel"
    },
    "DF": {
      "code": "DF",
      "class": "Decomposing and Fragmented precipitation particles"
    },
    "RG": {
      "code": "RG",
      "class": "Rounded Grains"
    },
    "FC": {
      "code": "FC",
      "class": "Faceted Crystals"
    },
    "DH": {
      "code": "DH",
      "class": "Depth Hoar"
    },
    "SH": {
      "code": "SH",
      "class": "Surface Hoar"
    },
    "MF": {
      "code": "MF",
      "class": "Melt Forms"
    },
    "MFcr": {
      "code": "MFcr",
      "class": "Melt-freeze crust"
    },
    "IF": {
      "code": "IF",
      "class": "Ice Formations"
    }
  },
  "button": {
    "yes": "yes",
    "no": "no"
  },
  "observations": {
    "aspect": "Esposizione",
    "authorName": "Nome dell'autore",
    "avalancheLength": "Lunghezza della valanga",
    "avalancheProblem": "Problema tipico valanghivo",
    "avalancheSize": "Dimension della valanga",
    "avalancheType": "Tipologia della valanga",
    "avalancheWidth": "Larghezza della valanga",
    "search": "Cerca",
    "count": "# osservazioni",
    "layers": {
      "observers": "Observers",
      "weatherStations": "Weather stations",
      "webcams": "Webcam",
      "observations": "Osservazioni"
    },
    "weatherStations": {
      "tooltips": {
        "globalRadiation": "Global radiation",
        "snowHeight": "altezza neve",
        "snowDifference24h": "Snow difference 24h",
        "snowDifference48h": "Snow difference 48h",
        "snowDifference72h": "Snow difference 72h",
        "airTemperature": "Air temperature",
        "airTemperatureMax": "Air temperature Max",
        "airTemperatureMin": "Air temperature Min",
        "surfaceTemperature": "Surface temperature",
        "surfaceHoar": "Potential surface hoar formation",
        "surfaceHoarCalc": "Potential surface hoar formation (Lehning et. al.)",
        "dewPoint": "Dew point temperature",
        "relativeHumidity": "Relative humidity",
        "windDirection": "Wind direction",
        "windSpeed": "Wind speed",
        "windGust": "Wind gust"
      }
    },
    "button": {
      "add": "Aggiungere osservazione",
      "save": "Salvare l'osservazione",
      "delete": "Cancellare l'osservazione",
      "deleteConfirm": "Cancellare l'osservazione?",
      "discard": "Scarta",
      "copyDate": "Copy event date"
    },
    "comment": "Commento",
    "content": "Contenuto",
    "dangerRating": "Grado pericolo valanghe",
    "elevation": "Quota",
    "elevationRange": "Elevation range",
    "elevationLowerBound": "Elevation lower bound",
    "elevationUpperBound": "Elevation upper bound",
    "eventDate": "Data dell'evento",
    "eventType": "Tipologia dell'evento",
    "eventTypes": {
      "IMPORTANT": "Evento importante",
      "NEIGHBOR_REGION": "Regione confinante",
      "NORMAL": "Evento normale",
      "PERSON_DEAD": "Evento con persone coinvolte (vittime)",
      "PERSON_INJURED": "Evento con persone coinvolte (feriti)",
      "PERSON_NO": "Evento senza coinvolgimento persone",
      "PERSON_UNINJURED": "Evento che coinvolge persone (non ferite)",
      "PERSON_UNKNOWN": "Evento (sconosciuto)",
      "TRAFFIC": "Traffico"
    },
    "fractureDepth": "Spessore del lastrone",
    "hasCoordinates": "Contiene coordinate",
    "incline": "Pendenza",
    "latitude": "Latitudine",
    "locationName": "Località",
    "longitude": "Longitudine",
    "personInvolvement": "Person involvement",
    "reportDate": "Data del report",
    "showTable": "Table",
    "withoutCoordinates": "Osservazioni senza coordinate:",
    "sidebar": {
      "filter": {
        "title": "Filtro"
      },
      "legend": {
        "title": "Leggenda"
      },
      "sources": {
        "title": "Fonti dei dati"
      },
      "views": {
        "title": "Views"
      }
    }
  },
  "personInvolvement": {
    "Dead": "Evento con persone coinvolte (vittime)",
    "Injured": "Evento con persone coinvolte (feriti)",
    "No": "Evento senza coinvolgimento persone",
    "Uninjured": "Evento che coinvolge persone (non ferite)",
    "Unknown": "Evento (sconosciuto)"
  },
  "units": {
    "cm": "cm",
    "mm": "mm",
    "m": "m"
  },
  "qfa": {
    "day": "Giorno",
    "time": "Time",
    "back": " Indietro",
    "parameters": {
      "DUST": "sahara dust",
      "N": "total cloud cover in /8",
      "Nh": "high cloud cover in /8",
      "Nm": "medium high cloud cover in /8",
      "Nl": "low cloud cover in /8",
      "N-CU": "cumulus cloud cover in /8",
      "AUSL.T": "trigger temperature",
      "SHW-IX": "Showalter-Index",
      "WX -CUF": "significant weather: NIL (none), RASH (rain), TS (thunder storm)",
      "RR": "total precipitation [mm]",
      "RR-str": "stratiform precipitation",
      "CONV": "convective precipitation [mm]",
      "SN": "precipitation in form of snow [mm]",
      "SN--RA": "snowline [m]",
      "QAO ": "{{value}} hPa eq. mean wind, direction and strength, [°] and [m/s]",
      "QAN": "mean wind on the ground, direction and strength, [°] and [m/s]",
      "QANmax": "max. wind (gusts) on the ground [m/s]",
      "T ": "{{value}} hPa eq. temperature [°C]",
      "T m": "temperature on {{value}}m [°C]",
      "T 2m": "temperature on 2m [°C]",
      "Tk 2m": "temperature on 2m [°K]",
      "T GND": "ground temperature",
      "Max-ADI": "maximum mixing temperature [°C]",
      "Min Max": "min./max. temperature in the last 24h",
      "FROST": "zero degree line [m]",
      "W cm": "500 hPa eq. vertical wind [cm/s]",
      "W --": "700 hPa eq. vertical wind [cm/s]",
      "W s": "{{value}} hPa eq. vertical wind [cm/s]",
      "H ": "{{value}} hPa geopotential height [m]",
      "RF ": "{{value}} hPa eq. relative moisture [%]"
    }
  }
}
