{
  "daytime": {
    "earlier": "Früher",
    "later": "Später"
  },
  "login": {
    "title": "Login",
    "text": "Melde dich mit deinem Account an",
    "button": "Login",
    "stressLevel": "Stresslevel",
    "errorDialog": {
      "message": "Benutzername oder Passwort falsch!",
      "accept": "Ok"
    },
    "placeholder": {
      "username": "Bitte Benutzernamen eingeben",
      "password": "Bitte Passwort eingeben"
    }
  },
  "sidebar": {
    "bulletins": "Lawinen.report",
    "dangerSources": "Gefahrenquellen",
    "observations": "Beobachtungen",
    "qfa": "QFA",
    "modelling": "Modellierung",
    "modellingForecast": "Vorhersage",
    "modellingZamg": "ZAMG Multimodel",
    "modellingZamgECMWF": "ZAMG ECMWF-EPS",
    "modellingZamgCLAEF": "ZAMG CLAEF-EPS",
    "modellingZamgMeteogram": "GeoSphere Meteogramm",
    "modellingSnowpack": "SNOWPACK Modell",
    "modellingSnowpackMeteo": "Modellierte Meteodaten SNOWPACK",
    "weatherbox": "Wetterbox",
    "awsome": "AWSOME",
    "snowpack": "SNOWPACK",
    "geosphere": "GeoSphere"
  },
  "menu": {
    "logout": "Logout",
    "language": "Sprache",
    "en": "Englisch",
    "de": "Deutsch",
    "fr": "Französisch",
    "it": "Italienisch",
    "es": "Spanisch",
    "ca": "Katalanisch",
    "oc": "Aranesisch",
    "fullscreen": "Vollbildschirm",
    "settings": "Einstellungen",
    "statistics": "Statistik",
    "admin": "Admin",
    "region": "Region",
    "education": "Ausbildung"
  },
  "matrix": {
    "label": {
      "snowpackStability": "Schneedeckenstabilität",
      "frequency": "Häufigkeit",
      "avalancheSize": "Lawinengröße",
      "dangerRating": "Gefahrenstufe",
      "override": "(Gefahrenstufe überschreiben)",
      "matrix": "Matrix",
      "referToFairStability": "Siehe mittlere Stabilität",
      "referToPoorStability": "Siehe schlechte Stabilität",
      "dangerLevel1": "Gefahrenstufe 1 (Gering)"
    }
  },
  "dangerSources": {
    "status": {
      "missing": "fehlt",
      "available": "verfügbar",
      "forecast": "Vorhersage",
      "analysis": "Analyse",
      "loading": "Gefahrenquellen werden geladen ...",
      "saveError": "Gefahrenquelle konnte nicht gespeichert werden!",
      "loadDangerSourcesError": "Gefahrenquellen konnten nicht geladen werden!",
      "loadVariantsError": "Varianten der Gefahrenquellen konnten nicht geladen werden!"
    },
    "variantStatus": {
      "active": "aktiv",
      "dormant": "schlafend",
      "inactive": "inaktiv"
    },
    "tooltip": {
      "read": "Gefahrenquellen lesen",
      "edit": "Gefahrenquellen bearbeiten"
    },
    "table": {
      "loadMore": "{{count}} Tage mehr laden",
      "title": {
        "forecast": "Vorhersage",
        "analysis": "Analyse"
      }
    },
    "create": {
      "variantStatus": "Status",
      "variantEditable": "Diese Variante kann bearbeitet werden.",
      "variantNotEditable": "Diese Variante kann nicht bearbeitet werden.",
      "noVariantsForSelectedDay": "Für dieses Datum sind keine Varianten definiert.",
      "tooltip": {
        "editDangerSource": "Gefahrenquelle bearbeiten",
        "loadVariants": "Varianten von gestern laden",
        "edit": "Kleinstregionen editieren",
        "copy": "Variante kopieren",
        "delete": "Variante löschen",
        "compare": "Variante vergleichen",
        "forecast": "Mit Vorhersage vergleichen"
      },
      "createDangerSource": "Gefahrenquelle erstellen",
      "createVariant": "Neue Variante erstellen",
      "noDangerSources": "Keine Gefahrenquellen",
      "title": {
        "avalanche": "Lawine",
        "matrix": "Matrix",
        "characteristics": "Merkmale"
      },
      "label": {
        "glidingSnowActivity": "Gleitschneeaktivität",
        "snowHeight": "Schneehöhe",
        "zeroDegreeIsotherm": "0°C isotherm",
        "looseSnowMoisture": "Feuchte des Lockerschnees",
        "looseSnowGrainShape": "Kornform des Lockerschnees",
        "slabGrainShape": "Kornform des Bretts",
        "slabThickness": "Dicke des Bretts",
        "slabHandHardness": "Handhärte des Bretts",
        "slabHardnessProfile": "Härte des Bretts",
        "slabEnergyTransferPotential": "Energieübertragungspotential des Bretts",
        "slabDistribution": "Verteilung des Bretts",
        "weakLayerGrainShape": "Kornform der Schwachschicht",
        "weakLayerGrainSize": "Korngröße der Schwachschicht",
        "weakLayerPersistent": "Persistenz der Schwachschicht",
        "weakLayerThickness": "Dicke der Schwachschicht",
        "weakLayerStrength": "Festigkeit der Schwachschicht",
        "weakLayerWet": "Ist die Schwachschicht feucht?",
        "weakLayerCrustAbove": "Kruste oberhalb der Schwachschicht",
        "weakLayerCrustBelow": "Kruste unterhalb der Schwachschicht",
        "weakLayerPosition": "Position der Schwachschicht",
        "weakLayerCreation": "Entstehung der Schwachschicht",
        "weakLayerDistribution": "Verteilung der Schwachschicht",
        "dangerSpotRecognizability": "Erkennbarkeit der Gefahrenstellen",
        "remoteTriggering": "Fernauslösungen",
        "hasDaytimeDependency": "Tageszeitliche Abhängigkeit",
        "dangerIncreaseWithElevation": "Zunahme der Gefahr mit der Höhe",
        "runoutIntoGreen": "Auslauf bis ins Grüne",
        "highestDangerAspect": "Exposition der höchsten Gefahr",
        "dangerPeak": "Gefahrenspitze",
        "slopeGradient": "Hangneigung",
        "naturalRelease": "Spontane Lawinen",
        "terrainTypes": "Geländeformen",
        "dangerSigns": "Gefahrenzeichen",
        "aspects": "Expositionen"
      },
      "editDangerSourceDialog": {
        "save": "Speichern",
        "cancel": "Abbrechen"
      }
    }
  },
  "avalancheType": {
    "title": "Lawinenart",
    "label": {
      "slab": "SCHNEEBRETT",
      "loose": "LOCKERSCHNEE",
      "glide": "GLEITSCHNEE"
    },
    "tooltip": {
      "slab": "Schneebrettlawine",
      "loose": "Lockerschneelawine",
      "glide": "Gleitschneelawine"
    }
  },
  "elevation": {
    "title": "Seehöhe",
    "high": "Unter",
    "low": "Über",
    "treeline": "Waldgrenze"
  },
  "bulletins": {
    "title": "Lawinen.report",
    "tooltip": {
      "edit": "Bulletin bearbeiten",
      "read": "Bulletin lesen",
      "copy": "Lawinenvorhersage kopieren",
      "paste": "Lawinenvorhersage einfügen",
      "cancel": "Kopieren abbrechen",
      "update": "Bearbeiten",
      "submit": "Abschicken",
      "submitUpdate": "Update abschicken",
      "publishChange": "Sofort publizieren (keine Nachrichten)",
      "publishUpdate": "Sofort publizieren",
      "caaml": "CAAML anzeigen",
      "json": "JSON anzeigen",
      "create": "Lawinenvorhersage erstellen",
      "preview": "Vorschau anzeigen (PDF)",
      "check": "Lawinenvorhersage prüfen",
      "info": "Publikationsstatus anzeigen",
      "publishAll": "Alle Regionen publizieren",
      "mediaFile": "Mediendatei hochladen"
    },
    "status": {
      "submittedInfo": "Wird um 17 Uhr publiziert",
      "resubmittedInfo": "Wird um 8 Uhr publiziert",
      "publishedInfo" : "Wurde publiziert",
      "noPublicationInfo" : "Wird NICHT automatisch publiziert",
      "loadingPreview": "Erzeuge PDF Vorschau ...",
      "loading": "Bulletin lädt ...",
      "loadMoreBulletins": "{{count}} weitere Bulletins laden",
      "publishing": "Bulletin wird publiziert ...",
      "submitting": "Bulletin wird abgeschickt ...",
      "saveError": "Bulletin konnte nicht gespeichert werden!",
      "loadBulletinsError": "Bulletins konnten nicht geladen werden!"
    },
    "create": {
      "tooltip": {
        "edit": "Kleinstregionen editieren",
        "copy": "Region kopieren",
        "delete": "Region löschen",
        "undo": "Letzte Änderung in der Warnregion rückgängig machen",
        "redo": "Letzte Änderung in der Warnregion wiederherstellen",
        "createRegion": "Neue Region",
        "cancel": "Abbrechen",
        "acceptSuggestion": "Vorgeschlagene Regionen annehmen",
        "rejectSuggestion": "Vorgeschlagene Regionen ablehnen",
        "saveChanges": "Änderungen speichern",
        "discardChanges": "Änderungen verwerfen",
        "incompleteAvalancheProblem": "Unvollständiges Lawinenproblem",
        "saveBulletin": "Lawinenvorhersage speichern",
        "discardBulletin": "Änderungen an der Lawinenvorhersage verwerfen",
        "loadBulletin": "Lawinenvorhersage vom Vortag laden",
        "uploadJsonBulletin": "Upload Bulletin in JSON Format",
        "downloadJsonBulletin": "Download Bulletin in JSON Format",
        "daytimeDependency": "Tageszeitliche Abhängigkeit",
        "treeline": "Waldgrenze",
        "tendency": {
          "decreasing": "Lawinengefahr nimmt ab",
          "steady": "Lawinengefahr bleibt unverändert",
          "increasing": "Lawinengefahr steigt",
          "danger": "Lawinengefahr"
        },
        "textcat": {
          "edit": "Bearbeiten",
          "copy": "Kopieren",
          "paste": "Einfügen",
          "example": "Beispieltext laden",
          "delete": "Löschen"
        },
        "moveUpAvalancheProblem": "Lawinenproblem nach oben schieben",
        "moveDownAvalancheProblem": "Lawinenproblem nach unten schieben",
        "dangerRatingDirectionUp": "Aufwärts",
        "dangerRatingDirectionDown": "Abwärts"
      },
      "save": " Speichern",
      "discard": " Verwerfen",
      "back": " Zurück",
      "createAggregatedRegion": " Neue Region",
      "pasteAggregatedRegion": " Region einfügen",
      "pendingSuggestions": "Vorschläge",
      "rejectSuggestion": "Ablehnen",
      "acceptSuggestion": "Akzeptieren",
      "cancelCopyBulletin": " Abbrechen",
      "createAvalancheProblem": " Neues Lawinenproblem",
      "ownRegions": "Eigene Regionen",
      "foreignRegions": "Fremde Regionen",
      "externalRegions": "Externe Regionen",
      "mapSelectRegion": "Regionen in der Karte auswählen.",
      "statusHeader": "Status des Bulletins",
      "suggestions": "Vorschläge",
      "noBulletinsForSelectedDay": "Keine Regionen für dieses Datum vorhanden.",
      "autosave": "Automatisch gespeichert",
      "regionEditable": "Diese Region kann bearbeitet werden.",
      "regionNotEditable": "Diese Region kann nicht bearbeitet werden.",
      "regionLocked": "Diese Region ist gesperrt.",
      "changeDangerRatingElevation": "Erweitere die Gefahrenstufe",
      "mapTop": "Karte oben",
      "mapLeft": "Karte seitlich",
      "showNotes": "Notizen anzeigen",
      "hideNotes": "Notizen ausblenden",
      "incompleteAvalancheProblem": "Unvollständig",
      "label": {
        "importantObservations": "Wichtige Beobachtungen",
        "avalancheProblems": "Lawinenproblem",
        "aspects": "Expositionen",
        "highlights": "Besondere Warnung",
        "avActivityHighlights": "Schlagzeile Gefahrensituation",
        "avActivityComment": "Beurteilung der Lawinengefahr",
        "snowpackStructureComment": "Beschreibung des Schneedeckenaufbaus",
        "dangerPatterns": "Gefahrenmuster",
        "tendencyComment": "Beschreibung der Tendenz",
        "notes": "Notizen"
      },
      "copyRegion": {
        "label": "Warnregion zu anderem Bulletin kopieren",
        "select": "Einfügen in ...",
        "currentBulletin": "Dieses Bulletin"
      },
      "title": {
        "avalancheProblem": "Lawinenprobleme",
        "dangerDescription": "Gefahrenbeurteilung",
        "forenoon": "Vormittag",
        "afternoon": "Nachmittag",
        "snowpackStructure": "Schneedeckenaufbau",
        "tendency": "Tendenz",
        "showTranslations": " Übersetzungen anzeigen",
        "hideTranslations": " Übersetzungen verbergen"
      },
      "placeholder": {
        "notes": "Schreib deine Notizen hier ..."
      },
      "pmDialog": {
        "noIFrameSupport": "Dein Browser unterstützt keine IFrames!"
      },
      "discardDialog": {
        "message": "Willst du die Änderungen verwerfen?",
        "accept": "Ja",
        "reject": "Nein"
      },
      "deleteAggregatedRegionDialog": {
        "message": "Willst du die Region löschen?",
        "accept": "Ja",
        "reject": "Nein"
      },
      "noRegionDialog": {
        "message": "Zumindest eine Kleinstregion muss deine sein.",
        "accept": "Ok"
      },
      "loadDialog": {
        "message": "Willst du die Lawinenvorhersage vom Vortag laden?",
        "accept": "Ja",
        "reject": "Nein"
      },
      "loadAutoSaveDialog": {
        "message": "Willst du die automatisch gespeicherte Lawinenvorhersage laden?",
        "accept": "Ja",
        "reject": "Nein"
      },
      "saveErrorDialog": {
        "message": "Beim Speichern der Lawinenvorhersage ist ein Fehler aufgetreten!",
        "accept": "Ok"
      },
      "changeErrorDialog": {
        "message": "Beim Ändern der Lawinenvorhersage ist ein Fehler aufgetreten!",
        "accept": "Ok"
      },
      "loadingErrorDialog": {
        "message": "Beim Laden der Lawinenvorhersage ist ein Fehler aufgetreten!",
        "accept": "Ok"
      },
      "loadingJsonFileErrorDialog": {
        "message": "Beim Laden des Bulletins aus der JSON Datei ist ein Fehler aufgetreten!",
        "accept": "Ok"
      },
      "avalancheProblemErrorDialog": {
        "message": "Lawinenproblem, Exposition, Schneedeckenstabilität, Häufigkeit und/oder Lawinengröße fehlen bei mindestens einem Lawinenproblem",
        "accept": "Ok"
      },
      "loadAvActivityCommentExampleTextDialog": {
        "message": "Für welche Situation möchtest du einen Beispieltext laden?",
        "newSnow": "Neuschnee",
        "windSlab": "Triebschnee",
        "persistentWeakLayers": "Altschnee",
        "wetSnow": "Nassschnee",
        "glidingSnow": "Gleitschnee",
        "favourableSituation": "Günstige Situation",
        "cancel": "Abbrechen"
      },
      "removeDaytimeDependencyDialog" : {
        "message": "Welche Lawinenprobleme möchtest du behalten?",
        "cancel": "Abbrechen"
      },
      "strategicMindsetDialog" : {
        "title": "Strategisches Mindset",
        "introduction": "Mit den „Strategic Mindsets“ soll unsere mentale Einstellung im Hinblick auf die Lawinensituation bzw. dessen Entwicklung in der entsprechenden Warnregion zum Ausdruck gebracht werden. Sie soll keine direkte Ableitung aus der Gefahrenbeurteilung darstellen, sondern vielmehr als intuitiver Eindruck der Gesamtsituation verstanden und verwendet werden. In erster Linie handelt es sich bei den „Strategic Mindsets“ also über ein Kommunikationsinstrument. Über die Mindsets und damit verbundenen Definitionen können wir Prognostikerinnen uns schnell und effizient über die Entwicklung der Gefahrensituation austauschen und eine gemeinsame Sprache in der Kommunikation finden. ",
        "description": {
          "title": "Beschreibung:",
          "text": {
            "assessment": "Es besteht <b>große Unsicherheit</b> in der Gefahrenbeurteilung aufgrund <b>unzureichender Informationen</b> aus dem Gelände.",
            "stepping_out": "<b>Abnehmende Tendenz</b> der Gefahrensituation (auch innerhalb einer Gefahrenstufe).",
            "status_quo": "Gleichbleibende Gefahrensituation. <b>Keine wesentliche Änderung</b> zum Vortag.",
            "stepping_back": "<b>Ansteigende Tendenz</b> der Gefahrensituation (auch innerhalb einer Gefahrenstufe).",
            "entrenchment": "<b>Annäherend gleichbleibende, angespannte Gefahrensituation.</b> Die Hauptgefahr geht von (einer) <b>störanfälligen, persistente(n) Schwachschicht(en)</b> aus.",
            "free_ride": "<b>Geringe Lawinengefahr</b> mit allgemein stabiler Schneedecke. Es sind nur kleine, oberflächliche Lawinen im extremen Steilgelände möglich. ",
            "high_alert": "Außergewöhnliche Situation mit <b>großer oder sehr großer Lawinengefahr</b>. Spontane Lawinen <b>sehr großen oder extremen Ausmaßes</b> sind möglich",
            "spring_diurnal": "Nassschneelawinen im Tagesverlauf, welche einem <b>klaren tageszeitlichen Gang</b> unterliegen sind maßgeblich für die Gefahreneinschätzung."
          }
        },
        "communication": {
          "title": "Kommunikation:",
          "text": {
            "assessment": "Lawinensituation muss lokal genau überprüft werden. Zurückhaltung wird empfohlen.",
            "stepping_out": "Allmählicher Rückgang der Gefahr. Es ist jedoch weiterhin Vorsicht geboten, die sich bessernde Situation sollte lokal beurteilt werden, bevor risikoreicheres Gelände betreten wird. ",
            "status_quo": "Gefahrensituation ist unverändert. Rückmeldungen von Beobachtern helfen, die Gefahrensituation besser zu umreißen. Günstigeres Gelände sollte hervorgehoben kommuniziert werden.",
            "stepping_back": "Klare Kommunikation des Anstiegs der Lawinengefahr - auch innerhalb einer Gefahrenstufe. Informationen zu den Ursachen unterstützen die Verständlichkeit in der Kommunikation.",
            "entrenchment": "Zurückhaltung und Vorsicht ist geboten. Situation ändert sich kaum. Wenn möglich möglichst genaue Informationen zu Verbreitung und Störanfälligkeit. Verstärkte Kommunikation von Beobachtungen, welche die Situation offenkundig machen und Zurückhaltung triggern.",
            "free_ride": "Wenig Text. Hervorheben der allgemein günstigen Gesamtsituation und potenziell isolierter Gefahrenbereiche.",
            "high_alert": "Kommunikation über alle Kanäle. Fokus liegt nicht mehr auf Wintersport. Gefahr auch in Bereichen, welche für gewöhnlich nicht lawinengefährdet sind.",
            "spring_diurnal": "Am Morgen günstige Situation, im Tagesverlauf Anstieg. Beurteilung sollte lokal durch Beobachten des Aufweichens der nächtlichen Schmelzharschkruste und der zunehmenden Durchnässung der Schneedecke erfolgen."
          }
        },
        "examples": {
          "title": "Beispiele:",
          "text": {
            "assessment": "Saisonbeginn.",
            "stepping_out": "Abkühlung führt nach einer feucht- warmen Wetterphase zu einem Rückgang der Gefahr von Nassschneelawinen.",
            "status_quo": "Gleichbleibend gefahrenbestimmendes Triebschneeproblem bei kalten Temperaturen, lockerem Schnee und Wind.",
            "stepping_back": "Ergiebiger Neuschneefall führt zu einem Anstieg der Gefahrensituation.",
            "entrenchment": "Kritisches Altschneeproblem mit Potenzial für gefährlich große Lawinen. Die Situation stabilisiert sich nur sehr langsam und erfordert viel Zurückhaltung.",
            "free_ride": "Geringe Lawinengefahr, keine persistenten Schwachschichten, jedwedes Gelände wurde bereits angespurt.",
            "high_alert": "Gefahrenstufe 4 oder höher aufgrund großer Neuschneemengen und/oder problematischer, anhaltender Schwachschichten.",
            "spring_diurnal": "Frühjahrssituation mit trockenem Hochdruckwetter und moderaten Temperaturen bei ansonsten überwiegend stabiler Altschneedecke."
          }
        },
        "strategicMindset": {
          "assessment": "Große Unsicherheit",
          "stepping_out": "Verbesserung",
          "status_quo": "Status Quo",
          "stepping_back": "Verschlechterung",
          "entrenchment": "Persistent Instabil",
          "free_ride": "Günstige Situation",
          "high_alert": "High Alert",
          "spring_diurnal": "Frühjahrssituation"
        }
      },
      "decisionTree" : {
        "filepath": "./assets/img/decision_tree-de.svg",
        "feedbackURL": "https://admin.avalanche.report/umfrage-entscheidungsbaum/de/",
        "decisionTree" : "Entscheidungsbaum",
        "feedback" : "Feedback",
        "save": "Speichern",
        "discard": "Verwerfen"
      }
    },
    "table": {
      "title": {
        "date": "Datum",
        "status": {
          "AT-02": "Kärnten",
          "AT-03": "Niederösterreich",
          "AT-04": "Oberösterreich",
          "AT-05": "Salzburg",
          "AT-06": "Steiermark",
          "AT-07": "Tirol",
          "AT-08": "Vorarlberg",
          "CH" : "Schweiz",
          "DE-BY" : "Bayern",
          "GEOSPHERE": "GEOSPHERE",
          "IT-32-TN": "Trentino",
          "IT-32-BZ": "Südtirol",
          "ES-CT-L": "Aran"
        }
      },
      "status": {
        "missing": "fehlt",
        "draft": "Entwurf",
        "submitted": "gesendet",
        "published": "publiziert",
        "updated": "verändert",
        "resubmitted": "Update gesendet",
        "republished": "Update publiziert"
      },
      "publishBulletinsDialog": {
        "message": "Willst du die Lawinenvorhersage publizieren?",
        "missingDangerRating": "Gefahrenstufe nicht definiert",
        "missingRegion": "Regionen nicht beurteilt",
        "duplicateRegion": "Regionen mehrfach beurteilt",
        "missingAvActivityHighlights": "Schlagzeile Gefahrensituation fehlt",
        "missingAvActivityComment": "Beurteilung der Lawinengefahr fehlt",
        "missingSnowpackStructureHighlights": "Schlagzeile des Schneedeckenaufbaus fehlt",
        "missingSnowpackStructureComment": "Beurteilung des Schneedeckenaufbaus fehlt",
        "pendingSuggestions": "Unbeantwortete Vorschläge",
        "incompleteTranslation": "Unvollständige Übersetzung",
        "accept": "Ja",
        "reject": "Nein"
      },
      "publicationStatusDialog": {
        "headline": "Status der Publikation",
        "publishedBy": "Publiziert von: ",
        "publishedAt": "Publiziert um: ",
        "validFor": "Gültig für: ",
        "title": {
          "prod": "Produktives System",
          "test": "Test System",
          "task": "Vorgang",
          "language": {
            "all": "ALLE",
            "de": "DE",
            "it": "IT",
            "en": "EN"
          }
        },

        "email": {
          "label": "E-Mails",
          "tooltip": "E-Mails versenden",
          "success": "{{prefix}}Emails erfolgreich verschickt!",
          "error": "{{prefix}}Emails konnten nicht verschickt werden!"
        },
        "telegram": {
          "label": "Telegram Nachrichten",
          "tooltip": "Telegram Nachrichten verschicken",
          "success": "{{prefix}}Telegram Nachrichten erfolgreich verschickt!",
          "error": "{{prefix}}Telegram Nachrichten konnten nicht verschickt werden!"
        },
        "push": {
          "label": "Push Nachrichten",
          "tooltip": "Push Nachrichten verschicken",
          "success": "{{prefix}}Push Nachrichten erfolgreich verschickt!",
          "error": "{{prefix}}Push Nachrichten konnten nicht verschickt werden!"
        },
        "accept": "Ok"
      },
      "mediaFileDialog": {
        "headline": "Mediendatei",
        "important": "WICHTIG",
        "missingFile": "Keine Datei ausgewählt!",
        "uploadError": "Upload fehlgeschlagen!",
        "accept": "Senden",
        "reject": "Abbrechen"
      },
      "publishBulletinsErrorDialog": {
        "message": "Beim Publizieren der Lawinenvorhersage ist ein Fehler aufgetreten!",
        "accept": "Ok"
      },
      "submitBulletinsDialog": {
        "message": "Willst du die Lawinenvorhersage absenden?",
        "missingDangerRating": "Gefahrenstufe nicht definiert",
        "missingRegion": "Regionen nicht beurteilt",
        "missingAvActivityHighlights": "Schlagzeile der Lawinengefahr fehlt",
        "missingAvActivityComment": "Beurteilung der Lawinengefahr fehlt",
        "missingSnowpackStructureHighlights": "Schlagzeile des Schneedeckenaufbaus fehlt",
        "missingSnowpackStructureComment": "Beurteilung des Schneedeckenaufbaus fehlt",
        "pendingSuggestions": "Unbeantwortete Vorschläge",
        "accept": "Ja",
        "reject": "Nein"
      },
      "submitBulletinsErrorDialog": {
        "message": "Beim Absenden der Lawinenvorhersage ist ein Fehler aufgetreten!",
        "accept": "Ok"
      },
      "submitBulletinsDuplicateRegionDialog": {
        "message": "Regionen sind mehrfach beurteilt! Lawinenvorhersage kann nicht abgesendet werden.",
        "accept": "Ok"
      },
      "checkBulletinsErrorDialog": {
        "message": "Beim Überprüfen der Lawinenvorhersage ist ein Fehler aufgetreten!",
        "accept": "Ok"
      },
      "previewErrorDialog": {
        "message": "Vorschau konnte nicht geladen werden!",
        "accept": "Ok"
      },
      "checkBulletinsDialog": {
        "message": "Lawinenvorhersage prüfen",
        "missingDangerRating": "Gefahrenstufe nicht definiert",
        "missingRegion": "Regionen nicht beurteilt",
        "missingAvActivityHighlights": "Schlagzeile der Lawinengefahr fehlt",
        "missingAvActivityComment": "Beurteilung der Lawinengefahr fehlt",
        "missingSnowpackStructureHighlights": "Schlagzeile des Schneedeckenaufbaus fehlt",
        "missingSnowpackStructureComment": "Beurteilung des Schneedeckenaufbaus fehlt",
        "pendingSuggestions": "Unbeantwortete Vorschläge",
        "incompleteTranslation": "Unvollständige Übersetzung",
        "ok": "(alles ok)",
        "accept": "Ok"
      },
      "publishAllDialog": {
        "message": "Willst du alle Regionen publizieren?",
        "accept": "Ja",
        "reject": "Nein"
      }
    }
  },
  "training": {
    "training": "Training",
    "timestamp": "Trainingszeitpunkt"
  },
  "admin": {
    "loadingStatistics": "CSV wird erzeugt ...",
    "server-configuration": {
      "title": "Server",
      "button": {
        "create": "Neuer Server",
        "save": "Speichern"
      },
      "table": {
        "name": "Name",
        "username": "Benutzername",
        "password": "Passwort",
        "publishAt5PM": "Publizieren um 17:00 Uhr",
        "publishAt8AM": "Publizieren um 08:00 Uhr",
        "pdfDirectory": "Ordner für PDF",
        "htmlDirectory": "Ordner für HTML",
        "serverImagesUrl": "Pfad zu Bildern am Server",
        "mapsPath": "Pfad zu Karten",
        "mediaPath": "Pfad zu Mediendateien",
        "mapProductionUrl": "URL zur Kartenproduktion",
        "apiUrl": "API URL"
      },
      "success": "Konfiguration erfolgreich gespeichert",
      "error": "Konfiguration konnte nicht gespeichert werden"
    },
    "region-configuration": {
      "title": "Region",
      "button": {
        "create": "Neue Region",
        "save": "Speichern"
      },
      "table": {
        "general": {
          "title": "Allgemein",
          "id": "ID",
          "microRegions": "Anzahl an Mikroregionen",
          "subRegions": "Subregionen",
          "superRegions": "Superregionen",
          "neighborRegions": "Nachbarregionen"
        },
        "publication": {
          "title": "Publikation",
          "publishBulletins": "Lawinenvorhersage veröffentlichen",
          "publishBlogs": "Blogs veröffentlichen",
          "createCaamlV5": "CAAML v5 erzeugen",
          "createCaamlV6": "CAAML v6 erzeugen",
          "createJson": "JSON erzeugen",
          "createMaps": "Karten erstellen",
          "createPdf": "PDF erzeugen",
          "sendEmails": "Emails verschicken",
          "createSimpleHtml": "Einfaches HTML erzeugen",
          "sendTelegramMessages": "Telegram Nachrichten verschicken",
          "sendPushNotifications": "Push Nachrichten verschicken",
          "pdfColor": "PDF Farbe",
          "emailColor": "Email Farbe",
          "pdfMapYAmPm": "Y für PDF Karte (am/pm)",
          "pdfMapYFd": "Y für PDF Karte (fd)",
          "pdfMapWidthAmPm": "Kartenbreite für PDF (am/pm)",
          "pdfMapWidthFd": "Kartenbreite für PDF (fd)",
          "pdfMapHeight": "Kartenhöhe für PDF",
          "pdfFooterLogo": "Logo für PDF Footer",
          "pdfFooterLogoColorPath": "Logo für PDF Footer (farbe)",
          "pdfFooterLogoBwPath": "Logo für PDF Footer (schwarz-weiß)",
          "mapXmax": "Karte X max",
          "mapXmin": "Karte X min",
          "mapYmax": "Karte Y max",
          "mapYmin": "Karte Y min",
          "simpleHtmlTemplateName": "Vorlage für einfaches HTML",
          "geoDataDirectory": "Ordner für Geodaten",
          "mapLogoColorPath": "Logo für Karte (farbe)",
          "mapLogoBwPath": "Logo für Karte (schwarz-weiß)",
          "mapLogoPosition": "Logoposition für Karte",
          "mapCenterLat": "Mittelpunkt der Karte (lat)",
          "mapCenterLng": "Mittelpunkt der Karte (long)",
          "imageColorbarColorPath": "Farbleiste (farbe)",
          "imageColorbarBwPath": "Farbleiste (schwarz-weiß)"
        },
        "components": {
          "title": "Komponenten",
          "enableDangerSources": "Gefahrenquellen aktivieren",
          "enableObservations": "Beobachtungen aktivieren",
          "enableModelling": "Modellierung aktivieren",
          "enableWeatherbox": "Wetterbox aktivieren"
        },
        "configuration": {
          "title": "Konfiguration",
          "showMatrix": "Matrix anzeigen",
          "enableMediaFile": "Mediendateien aktivieren",
          "enableStrategicMindset": "Strategisches Mindset aktivieren",
          "enableStressLevel": "Stresslevel aktivieren",
          "enableAvalancheProblemCornices": "Lawinenproblem WECHTEN aktivieren",
          "enableAvalancheProblemNoDistinctAvalancheProblem": "Lawinenproblem KEIN AUSGEPRÄGTES PROBLEM aktivieren"
        }
      },
      "success": "Konfiguration erfolgreich gespeichert",
      "error": "Konfiguration konnte nicht gespeichert werden"
    },
    "users": {
      "title": "Benutzer",
      "button": {
        "create": "Benutzer anlegen"
      },
      "tooltip": {
        "edit": "Benutzer bearbeiten",
        "changePassword": "Passwort ändern",
        "delete": "Benutzer löschen"
      },
      "table": {
        "name": "Name",
        "email": "E-Mail",
        "organization": "Organisation",
        "regions": "Regionen",
        "roles": "Rollen"
      },
      "deleteUserModalDialog": {
        "message": "Willst du den Benutzer löschen?",
        "accept": "Ja",
        "reject": "Nein"
      },
      "deleteUser": {
        "success": "Benutzer gelöscht",
        "error": "Benutzer konnte nicht gelöscht werden"
      },
      "createUser": {
        "success": "Benutzer erstellt",
        "error": "Benutzer konnte nicht angelegt werden"
      },
      "updateUser": {
        "success": "Benutzer aktualisiert",
        "error": "Benutzer konnte nicht aktualisiert werden"
      }
    },
    "createUser": {
      "table": {
        "image": "Bild",
        "name": "Name",
        "email": "E-Mail",
        "organization": "Organisation",
        "password": "Passwort",
        "password2": "Passwort wiederholen",
        "roles": "Rollen",
        "regions": "Regionen"
      },
      "error": {
        "name": {
          "required": "Bitte Namen eingeben"
        },
        "email": {
          "required": "Bitte E-Mail Adresse eingeben",
          "invalid": "E-Mail Adresse ist ungültig"
        },
        "password": {
          "required": "Bitte Passwort eingeben",
          "length": "Passwort muss mindestens 8 Zeichen lang sein",
          "mismatch": "Passwörter stimmen nicht überein"
        }
      },
      "button": {
        "create": "Benutzer anlegen",
        "update": "Benutzer aktualisieren",
        "cancel": "Abbrechen"
      }
    },
    "observations": {
      "table": "Tabelle",
      "map": "Karte",
      "gallery": "Galerie",
      "filters": "Filter",
      "title": "Rückmeldungen",
      "newObservation": "Neue Beobachtung",
      "exportObservations": "Beobachtungen exportieren",
      "toggleFilterBar": "Filter anzeigen/verbergen",
      "button": {
        "submit": "Herunterladen"
      },
      "label": {
        "dateRange": "Zeitspanne",
        "observer": "Beobachter"
      },
      "selectRegion": {
        "placeholder": "Region",
        "selectionLabel": "{0} Region(en) ausgewählt"
      },
      "selectSources": {
        "placeholder": "Quellen",
        "selectionLabel": "{0} Quelle(n) ausgewählt"
      },
      "charts": {
        "charts": "Diagramme",
        "nan": "Ohne Angabe",
        "classify": "Klassifizieren",
        "label": "Bezeichnung",
        "invert": "Invert",
        "reset": "Reset",
        "aspect": {
          "caption": "Exposition"
        },
        "elevation": {
          "caption": "Seehöhe"
        },
        "stability": {
          "caption": "Stabilität"
        },
        "observationType": {
          "caption": "Beobachtungstyp"
        },
        "importantObservation": {
          "caption": "Wichtig"
        },
        "avalancheProblem": {
          "caption": "Lawinenproblem"
        },
        "dangerPattern": {
          "caption": "Gefahrenmuster"
        },
        "days": {
          "caption": "Kalendertag"
        }
      }
    },
    "blog": {
      "title": "Blog",
      "language": {
        "title": "Sprache",
        "de": "DE",
        "it": "IT",
        "en": "EN"
      },
      "all": {
        "tooltip": "Emails, Telegram Nachrichten und Push Nachrichten verschicken",
        "success": "{{prefix}}Email, Telegram Nachrichten und Push Nachrichten erfolgreich verschickt!",
        "error": "{{prefix}}Email, Telegram Nachrichten und Push Nachrichten konnten nicht verschickt werden!"
      },
      "email": {
        "tooltip": "E-Mails versenden",
        "success": "{{prefix}}Emails erfolgreich verschickt!",
        "error": "{{prefix}}Emails konnten nicht verschickt werden!"
      },
      "telegram": {
        "tooltip": "Publizieren via Telegram Kanal",
        "success": "{{prefix}}Telegram Nachrichten erfolgreich verschickt!",
        "error": "{{prefix}}Telegram Nachrichten konnten nicht verschickt werden!"
      },
      "push": {
        "tooltip": "Push Nachrichten verschicken",
        "success": "{{prefix}}Push Nachrichten erfolgreich verschickt!",
        "error": "{{prefix}}Push Nachrichten konnten nicht verschickt werden!"
      }
    }
  },
  "settings": {
    "title": "Einstellungen",
    "changePassword": {
      "title": "Passwort ändern",
      "oldPassword": {
        "label": "Derzeitiges Passwort",
        "placeholder": "Derzeitiges Passwort"
      },
      "newPassword1": {
        "label": "Neues Passwort",
        "placeholder": "Neues Passwort"
      },
      "newPassword2": {
        "label": "Neues Passwort (wiederholen)",
        "placeholder": "Neues Passwort"
      },
      "submit": "Passwort ändern",
      "passwordChanged": "Passwort erfolgreich geändert",
      "passwordChangeError": "Passwort konnte nicht geändert werden",
      "passwordIncorrect": "Derzeitiges Passwort falsch",
      "error": {
        "oldPassword": {
          "required": "Bitte gib dein derzeitiges Passwort ein"
        },
        "newPassword": {
          "required": "Bitte gib ein neues Passwort ein",
          "mismatch": "Passwörter stimmen nicht überein"
        }
      }
    },
    "display": {
      "title": "Anzeige",
      "table": {
        "showCaaml": "CAAML anzeigen",
        "showJson": "JSON anzeigen"
      }
    }
  },
  "statistics": {
    "title": "Statistik",
    "button": {
      "submit": "Herunterladen"
    },
    "label": {
      "dateRange": "Zeitspanne",
      "extended": "Erweitert",
      "duplicates": "Duplikate"
    }
  },

  "dangerRating": {
    "noRating": "keine Beurteilung",
    "low": "gering",
    "moderate": "mäßig",
    "considerable": "erheblich",
    "high": "groß",
    "veryHigh": "sehr groß"
  },
  "avalancheProblem": {
    "newSnow": "Neuschnee",
    "new_snow": "New snow",
    "windSlab": "Triebschnee",
    "wind_slab": "Triebschnee",
    "persistentWeakLayers": "Altschnee",
    "persistent_weak_layers": "Altschnee",
    "wetSnow": "Nassschnee",
    "wet_snow": "Nassschneeproblem",
    "glidingSnow": "Gleitschnee",
    "gliding_snow": "Gleitschneeproblem",
    "cornices": "Wechten",
    "noDistinctAvalancheProblem": " Kein ausgeprägtes Lawinenproblem",
    "no_distinct_avalanche_problem": " Kein ausgeprägtes Lawinenproblem",
    "favourableSituation": "Günstige Situation",
    "favourable_situation": "Günstige Situation"
  },
  "dangerPattern": {
    "dp1": "gm.1: bodennahe schwachschicht",
    "dp2": "gm.2: gleitschnee",
    "dp3": "gm.3: regen",
    "dp4": "gm.4: kalt auf warm / warm auf kalt",
    "dp5": "gm.5: schnee nach langer kälteperiode",
    "dp6": "gm.6: lockerer schnee und wind",
    "dp7": "gm.7: schneearm neben schneereich",
    "dp8": "gm.8: eingeschneiter oberflächenreif",
    "dp9": "gm.9: eingeschneiter graupel",
    "dp10": "gm.10: frühjahrssituation"
  },
  "strategicMindset": {
    "title": "Strategisches Mindset",
    "assessment": "Große Unsicherheit",
    "stepping_out": "Verbesserung",
    "status_quo": "Status Quo",
    "stepping_back": "Verschlechterung",
    "entrenchment": "Persistent Instabil",
    "free_ride": "Günstige Situation",
    "high_alert": "High Alert",
    "spring_diurnal": "Frühjahrssituation"
  },
  "observationType": {
    "TimeSeries": "Zeitreihe",
    "Incident": "Ereignis",
    "Profile": "Schneeprofil",
    "Closure": "Sperre",
    "Blasting": "Sprengung",
    "Avalanche": "Lawine",
    "Evaluation": "Evaluierung",
    "SimpleObservation": "Einfache Beobachtung",
    "Webcam": "Webcam"
  },
  "importantObservation": {
    "SnowLine": "Schneefallgrenze",
    "SurfaceHoar": "Oberflächenreif",
    "Graupel": "Graupel",
    "StabilityTest": "Stabilitätstest",
    "IceFormation": "Eisbildung",
    "VeryLightNewSnow": "Sehr leichter Neuschnee"
  },
  "aspect": {
    "N": "N",
    "NE": "NO",
    "E": "O",
    "SE": "SO",
    "S": "S",
    "SW": "SW",
    "W": "W",
    "NW": "NW"
  },
  "role": {
    "SUPERADMIN": "Super Administrator",
    "ADMIN": "Administrator",
    "FORECASTER": "Lawinenwarner",
    "FOREMAN": "Vorarbeiter",
    "OBSERVER": "Beobachter"
  },
  "tendency": {
    "decreasing": " Lawinengefahr sinkt",
    "steady": " Lawinengefahr bleibt gleich",
    "increasing": " Lawinengefahr steigt"
  },
  "snowpackStability": {
    "unknown": "unbekannt",
    "good": "gut",
    "fair": "mittel",
    "poor": "schlecht",
    "very_poor": "sehr schlecht"
  },
  "frequency": {
    "none": "(fast) keine",
    "few": "wenige",
    "some": "einige",
    "many": "viele"
  },
  "avalancheSize": {
    "small": "klein",
    "medium": "mittel",
    "large": "groß",
    "very_large": "sehr groß",
    "extreme": "extrem"
  },
  "complexity": {
    "easy": "Einfach",
    "challenging": "Fordernd",
    "complex": "Komplex"
  },
  "glidingSnowActivity": {
    "low": "gering",
    "medium": "mittel",
    "high": "groß"
  },
  "wetness": {
    "dry": "trocken",
    "moist": "feucht",
    "wet": "nass"
  },
  "characteristic": {
    "low": "gering",
    "medium": "mittel",
    "high": "groß",
    "very_high": "sehr groß"
  },
  "thickness": {
    "thin": "dünn",
    "thick": "dick"
  },
  "snowpackPosition": {
    "upper": "oben",
    "middle": "mittig",
    "lower": "unten",
    "ground": "Boden"
  },
  "creationProcess": {
    "radiation_recrystallization": "Radiation recrystallization",
    "diurnal_recrystallization": "Diurnal recrystallization",
    "melt_layer_recrystallization": "Melt-layer recrystallization"
  },
  "recognizability": {
    "very_easy": "sehr leicht",
    "easy": "leicht",
    "hard": "schwer",
    "very_hard": "sehr schwer"
  },
  "distribution": {
    "isolated": "vereinzelt",
    "specific": "eingrenzbar",
    "widespread": "weitverbreited"
  },
  "probability": {
    "likely": "wahrscheinlich",
    "possible": "möglich",
    "unlikely": "unwahrscheinlich"
  },
  "detailedDaytime": {
    "evening": "Abend",
    "first_night_half": "Erste Nachthälfte",
    "second_night_half": "Zweite Nachthälfte",
    "morning": "Am Morgen",
    "forenoon": "Vormittag",
    "afternoon": "Nachmittag"
  },
  "slopeGradient": {
    "moderately_steep": "mäßig steil",
    "steep": "steil",
    "very_steep": "sehr steil",
    "extremely_steep": "extrem steil"
  },
  "handHardness": {
    "fist": "F",
    "four_fingers": "4F",
    "one_finger": "1F",
    "pencil": "P",
    "knife": "K",
    "ice": "I"
  },
  "hardnessTendency": {
    "decreasing": "Weicher an der Oberfläche",
    "steady": "gleichmäßig",
    "increasing": "Zunahme mit der Tiefe"
  },
  "terrainType": {
    "gullies": "Rinnen",
    "bowls": "Mulden",
    "pass_areas": "Passlagen",
    "ridge_lines": "Kammlagen"
  },
  "dangerSign": {
    "shooting_cracks": "Rissbildung",
    "whumpfing": "Setzungsgeräusche"
  },
  "changeRegionDialog": {
    "message": "Möchtest du die Region ändern?",
    "accept": "Ja",
    "reject": "Nein"
  },
  "zamg": {
    "table": "Tabelle",
    "map": "Karte",
    "region": "Region",
    "newSnow": "New snow"
  },
  "snowpack": {
    "plotType": {
      "LWC_stratigraphy": "Nassschnee: Wassergehalt",
      "wet_snow_instability": "Nassschnee: Stabilität nasse Schneedecke",
      "Sk38_stratigraphy": "Altschnee: SK38 Stabilitätsindex",
      "stratigraphy": "Altschnee: Schneeckenaufbau"
    },
    "aspect": {
      "flat": "Flachfeld",
      "north": "Nordhang",
      "south": "Südhang"
    },
    "meteo": {
      "new_snow_plot_3day": "Neuschnee (3T)",
      "new_snow_plot_7day": "Neuschnee (7T)",
      "new_snow_plot_1month": "Neuschnee (31T)",
      "new_snow_plot_season": "Neuschnee (Saison)",
      "new_snow_plot_forecast": "Neuschnee (Prognose)",
      "wet_snow_plot_3day": "Nassschnee (3T)",
      "wet_snow_plot_7day": "Nassschnee (7T)",
      "wet_snow_plot_1month": "Nassschnee (31T)",
      "wet_snow_plot_season": "Nassschnee (Saison)",
      "wet_snow_plot_forecast": "Nassschnee (Prognose)",
      "HS_table_24h": "Tabelle Schneehöhe (1T)",
      "HS_table_72h": "Tabelle Schneehöhe (3T)",
      "HS_table_season": "Tabelle Schneehöhe (Saison)",
      "HS_table_forecast": "Tabelle Schneehöhe (Prognose)",
      "TA_table_24h": "Tabelle Temperatur (1T)",
      "TA_table_72h": "Tabelle Temperatur (3T)",
      "TA_table_season": "Tabelle Temperatur (Saison)"
    }
  },
  "grainShape": {
    "PP": {
      "code": "PP",
      "class": "Neuschnee"
    },
    "PPgp": {
      "code": "PPgp",
      "class": "Graupel"
    },
    "DF": {
      "code": "DF",
      "class": "Filz"
    },
    "RG": {
      "code": "RG",
      "class": "kleine runde Kristalle"
    },
    "FC": {
      "code": "FC",
      "class": "kantige Kristalle"
    },
    "DH": {
      "code": "DH",
      "class": "Tiefenreif"
    },
    "SH": {
      "code": "SH",
      "class": "Oberflächenreif"
    },
    "MF": {
      "code": "MF",
      "class": "Schmelzformen"
    },
    "MFcr": {
      "code": "MFcr",
      "class": "Schmelzkruste"
    },
    "IF": {
      "code": "IF",
      "class": "Eislamelle"
    }
  },
  "button": {
    "yes": "ja",
    "no": "nein"
  },
  "observations": {
    "aspect": "Exposition",
    "authorName": "Autor Name",
    "avalancheLength": "Lawinenlänge",
    "avalancheProblem": "Lawinenproblem",
    "avalancheSize": "Lawinengröße",
    "avalancheType": "Lawinenart",
    "avalancheWidth": "Lawinenbreite",
    "search": "Suchen",
    "count": "# Beobachtungen:",
    "layers": {
      "observers": "Beobachter",
      "weatherStations": "Wetterstationen",
      "webcams": "Webcams",
      "observations": "Beobachtungen"
    },
    "weatherStations": {
      "tooltips": {
        "globalRadiation": "Globalstrahlung",
        "snowHeight": "Schneehöhe",
        "snowDifference24h": "Schneehöhendifferenz 24h",
        "snowDifference48h": "Schneehöhendifferenz 48h",
        "snowDifference72h": "Schneehöhendifferenz 72h",
        "airTemperature": "Lufttemperatur",
        "airTemperatureMax": "Maximale Lufttemperatur",
        "airTemperatureMin": "Minimale Lufttemperatur",
        "surfaceTemperature": "Oberflächentemperatur",
        "surfaceHoar": "Potential zur Oberflächenreifbildung",
        "surfaceHoarCalc": "Potential zur Oberflächenreifbildung (Lehning et. al.)",
        "dewPoint": "Taupunkt-Temperatur",
        "relativeHumidity": "Relative Luftfeuchtigkeit",
        "windDirection": "Windrichtung",
        "windSpeed": "Windgeschwindigkeit",
        "windGust": "Wind Böe"
      }
    },
    "button": {
      "add": "Beobachtung erstellen",
      "save": "Beobachtung speichern",
      "delete": "Beobachtung löschen",
      "deleteConfirm": "Beobachtung wirklich löschen?",
      "discard": "Verwerfen",
      "copyDate": "Ereignisdatum kopieren"
    },
    "comment": "Kommentar",
    "content": "Inhalt",
    "dangerRating": "Gefahrenstufe",
    "elevation": "Seehöhe",
    "elevationRange": "Höhenband",
    "elevationLowerBound": "Untere Höhengrenze",
    "elevationUpperBound": "Obere Höhengrenze",
    "eventDate": "Ereignisdatum",
    "eventType": "Ereignisart",
    "eventTypes": {
      "IMPORTANT": "Wichtiges Ereignis",
      "NEIGHBOR_REGION": "Nachbarregion",
      "NORMAL": "Normales Ereignis",
      "PERSON_DEAD": "Personenbeteiligung (tot)",
      "PERSON_INJURED": "Personenbeteiligung (verletzt)",
      "PERSON_NO": "Keine Personenbeteiligung",
      "PERSON_UNINJURED": "Personenbeteiligung (unverletzt)",
      "PERSON_UNKNOWN": "Ereignis (unbekannt)",
      "TRAFFIC": "Verkehr"
    },
    "fractureDepth": "Anrisshöhe",
    "hasCoordinates": "Koordinaten vorhanden",
    "incline": "Hangneigung",
    "latitude": "Breitengrad",
    "locationName": "Ort",
    "longitude": "Längengrad",
    "personInvolvement": "Personenbeteiligung",
    "reportDate": "Meldedatum",
    "showTable": "Tabelle",
    "withoutCoordinates": "Beobachtungen ohne Koordinaten:",
    "sidebar": {
      "filter": {
        "title": "Filter"
      },
      "legend": {
        "title": "Legende"
      },
      "sources": {
        "title": "Datenquellen"
      },
      "views": {
        "title": "Ansichten"
      }
    }
  },
  "personInvolvement": {
    "Dead": "Personenbeteiligung (tot)",
    "Injured": "Personenbeteiligung (verletzt)",
    "No": "Keine Personenbeteiligung",
    "Uninjured": "Personenbeteiligung (unverletzt)",
    "Unknown": "Ereignis (unbekannt)"
  },
  "units": {
    "cm": "cm",
    "mm": "mm",
    "m": "m"
  },
  "qfa": {
    "day": "Kalendertag",
    "time": "Zeit",
    "back": " Zurück",
    "parameters": {
      "DUST": "Saharastaub",
      "N": "Gesamtbewölkung in /8",
      "Nh": "hohe Bewölkung in /8",
      "Nm": "mittelhohe Bewölkung in /8",
      "Nl": "niedrige Bewölkung in /8",
      "N-CU": "Cumulusbewölkung in /8",
      "AUSL.T": "Triggertemperatur",
      "SHW-IX": "Showalter-Index",
      "WX -CUF": "signifikantes Wetter: NIL (keine), RASH (Regen), TS (Gewitter)",
      "RR": "Gesamtniederschlag [mm]",
      "RR-str": "schichtweiser Niederschlag",
      "CONV": "konvektiver Niederschlag [mm]",
      "SN": "Niederschlag in Form von Schnee [mm]",
      "SN--RA": "Schneefallgrenze [m]",
      "QAO ": "{{value}} hPa eq. Mittelwind, Windrichtung und -stärke, [°] und [m/s]",
      "QAN": "Mittlerer Bodenwind, Windrichtung aund -stärke, [°] und [m/s]",
      "QANmax": "Bodenwind max. (Böe) [m/s]",
      "T ": "{{value}} hPa equivalente Temperatur [°C]",
      "T m": "Temperatur auf {{value}}m [°C]",
      "T 2m": "Temperatur auf 2m [°C]",
      "Tk 2m": "Temperatur auf 2m [°K]",
      "T GND": "Bodentemperatur",
      "Max-ADI": "maximum mixing temperature [°C]",
      "Min Max": "Minimum/Maximum Temperatur der letzten 24h",
      "FROST": "Nullgrad Grenze [m]",
      "W cm": "500 hPa equivalent vertikaler Wind [cm/s]",
      "W --": "700 hPa equivalent vertikaler Wind [cm/s]",
      "W s": "{{value}} hPa equivalent vertikaler Wind [cm/s]",
      "H ": "{{value}} hPa geopotentielle Höhe [m]",
      "RF ": "{{value}} hPa equivalente relative Feuchte [%]"
    }
  }
}
